/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-lines */
/* eslint-disable no-lonely-if */
/* eslint-disable default-case */
/* eslint-disable complexity */

import { PageContainer, PageHeader, PageTitle } from '../../styles/common';

/* eslint-disable max-statements */

import * as Style from './importers.style';

import { CustomTable, SearchBox } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useImportersTableColumns } from './importers-table.columns';
import {
  fetchImporters,
  fetchImportersMetadata,
  selectImporters,
  selectImportersIsLoading,
  selectImportersMeta,
} from '../../stores/importerSlice';
import { useEffect, useState } from 'react';

const Importers = () => {
  const dispatch = useDispatch();
  const columns = useImportersTableColumns();

  const [criteria, setCriteria] = useState({
    filters: {
      searchQuery: '',
    },
    pagination: {
      page: 1,
      size: 10,
    },
  });

  const importersList = useSelector(selectImporters);
  const importersMeta = useSelector(selectImportersMeta);
  const isLoading = useSelector(selectImportersIsLoading);

  useEffect(() => {
    dispatch(fetchImporters(criteria));
    dispatch(fetchImportersMetadata(criteria.filters));
  }, [criteria]);

  const handleSearch = (searchQuery: string) => {
    setCriteria({
      ...criteria,
      filters: {
        ...criteria.filters,
        searchQuery,
      },
    });
  };

  const handlePaginationChange = ({ page, size }) => {
    setCriteria({
      ...criteria,
      pagination: {
        page,
        size,
      },
    });
  };

  return (
    <PageContainer isRelative>
      <PageHeader>
        <PageTitle style={{ fontWeight: 700, fontSize: '30px' }}>Importers</PageTitle>
        <Style.ImporterControls>
          {/* temporary removed */}
          {/* <Button primary>Add new Importer</Button> */}
          <SearchBox handleSearch={handleSearch} placeholder="Search " />
        </Style.ImporterControls>
      </PageHeader>

      <CustomTable
        columns={columns.current}
        pagination={{ page: 1, size: 10, direction: 1 }}
        sorting={{ direction: 'asc', field: 'messageInfo.batchId' }}
        onPaginationChange={handlePaginationChange}
        data={importersList || []}
        total={importersMeta.total}
        isLoading={isLoading}
        disableJumpToLastPage={false}
      />
    </PageContainer>
  );
};

export default Importers;
