import { FC } from 'react';

import sendIcon from '../../assets/icons/action-icon.svg';
import anomaliesHandledIcon from '../../assets/icons/anomalies-handled-icon.svg';
import anomaliesHSCodeOrDescriptionIcon from '../../assets/icons/anomaly-hs-code-icon.svg';
import anomalyValueIcon from '../../assets/icons/anomaly-value-icon.svg';
import archiveIcon from '../../assets/icons/archive-icon.svg';
import actionsIcon from '../../assets/icons/contextual-icon.svg';
import customsProcessingIcon from '../../assets/icons/customs-processing-icon.svg';
import customsSyncIcon from '../../assets/icons/customs-sync-icon.svg';
import downloadIcon from '../../assets/icons/download-icon.svg';
import mailIcon from '../../assets/icons/mail.svg';
import noSentAnomaliesIcon from '../../assets/icons/not-sent-no-anomalies-icon.svg';
import viewImageIcon from '../../assets/icons/picture.svg';
import refactorIcon from '../../assets/icons/refactor.svg';
import reportIcon from '../../assets/icons/report-icon.svg';
import riskAnalysisReportIcon from '../../assets/icons/risk-analysis-report-icon.svg';
import sendRiskAnalysisToCustomerIcon from '../../assets/icons/send-risk-analysis-to-customer-icon.svg';
import sendingIcon from '../../assets/icons/sending-icon.svg';
import failedIcon from '../../assets/icons/status-failed-icon.svg';
import notReleasedIcon from '../../assets/icons/status-not-released-icon.svg';
import notSentIcon from '../../assets/icons/status-not-sent-icon.svg';
import processingIcon from '../../assets/icons/status-processing-icon.svg';
import rejectedIcon from '../../assets/icons/status-rejected-icon.svg';
import releasedIcon from '../../assets/icons/status-released-icon.svg';
import deleteIcon from '../../assets/icons/trash-icon.svg';
import updateIcon from '../../assets/icons/update-icon.svg';
import uploadingIcon from '../../assets/icons/upload-icon.svg';
import viewIcon from '../../assets/icons/view-icon.svg';
import { SyncingIndicator } from '../../components';
import { PageContainer, PageHeader, PageTitle } from '../../styles/common';
import {
  CategoriesContainer,
  CategoryContainer,
  CategoryImage,
  CategoryText,
  HelpContainer,
  RowContainer,
  SectionContainer,
} from './help.styles';

const ACTIONS = 'Actions';
const ALL_ANOMALIES_HANDLED_WITH_PROPOSAL = 'All anomalies handled with proposal';
const ANOMALIES_DETECTED_ALL_HANDLED_BY_USER = 'Anomalies detected, all handled by user';
const ANOMALIES_DETECTED_HS_CODES = 'Anomalie(s) detected: HS codes and/or description';
const ANOMALIES_DETECTED_NO_ACTION_REQUIRED = 'Anomalies detected, no action required';
const ANOMALIES_DETECTED_VALUE = 'Anomalie(s) detected: value';
const ARCHIVE_UNARCHIVE = 'Archive/Unarchive';
const BATCH_STATUS = 'Batch status';
const CUSTOMS_NOTIFICATIONS_SYNCHRONIZATION = 'Customs notifications synchronization';
const CUSTOMS_SYNC = 'Customs sync';
const CUSTOMS_VALIDATION_IN_PROGRESS = 'Validation in progress (waiting for customs answer)';
const DECLARATION_STATUS = 'Declaration status';
const DECLARATIONS_NOT_RELEASED = 'Declarations not-released';
const DECLARATIONS_REJECTED = 'Declarations rejected';
const DECLARATIONS_RELEASED = 'Declarations released';
const DELETE = 'Delete';
const DOWNLOAD_FILE = 'Download file';
const EDIT = 'Edit';
const GENERATE_REPORT = 'Generate customs report';
const GOOD_ITEMS_STATUS = 'Good items status';
const HELP = 'Help';
const INVALID_FILE = 'Invalid file';
const NO_ANOMALIES_DETECTED = 'No anomalies detected';
const NO_ANOMALIES_NO_RISK_ANALYSIS = 'No anomalies - No risk analysis';
const NO_ANOMALIES_NO_RISK_ANALYSIS_DONE = 'No anomalies - No risk analysis done';
const NOT_SENT_NO_RISK_ANALYSIS = 'Not sent, no risk analysis';
const NOT_SENT_RISK_ANALYSIS_DONE_NO_ANOMALIES_DETECTED = 'Not sent, risk analysis done, no anomalies detected';
const RISK_ANALYSIS_REPORT = 'Risk analysis report';
const SEND = 'Send';
const SEND_RISK_ANALYSIS_TO_CUSTOMER = 'Send risk analysis to customer';
const SENDING = 'Sending';
const SEND_BY_EMAIL = 'Send by email';
const UPDATE = 'Update';
const UPLOADING_FILE_SAVING_DECLARATION_AND_RISK_ANALYSIS_UPDATING_BATCH =
  'Uploading file, saving declaration and risk analysis. Updating batch';
const VALIDATION_IN_PROGRESS = 'Validation in progress (waiting for customer answer)';
const VIEW_DETAILS = 'View details';
const VIEW_IMAGE = 'View image';

const BATCH_STATUS_DATA = [
  { id: 0, icon: uploadingIcon, text: UPLOADING_FILE_SAVING_DECLARATION_AND_RISK_ANALYSIS_UPDATING_BATCH },
  { id: 1, icon: anomaliesHandledIcon, text: ALL_ANOMALIES_HANDLED_WITH_PROPOSAL },
  { id: 2, icon: sendingIcon, text: SENDING },
  { id: 3, icon: failedIcon, text: INVALID_FILE },
  { id: 4, icon: customsProcessingIcon, text: VALIDATION_IN_PROGRESS },
  { id: 5, icon: releasedIcon, text: DECLARATIONS_RELEASED },
  { id: 6, icon: anomaliesHSCodeOrDescriptionIcon, text: ANOMALIES_DETECTED_HS_CODES },
  { id: 7, icon: noSentAnomaliesIcon, text: NOT_SENT_RISK_ANALYSIS_DONE_NO_ANOMALIES_DETECTED },
  { id: 8, icon: rejectedIcon, text: DECLARATIONS_REJECTED },
  { id: 9, icon: anomalyValueIcon, text: ANOMALIES_DETECTED_VALUE },
  { id: 10, icon: notSentIcon, text: NOT_SENT_NO_RISK_ANALYSIS },
  { id: 11, icon: notReleasedIcon, text: DECLARATIONS_NOT_RELEASED },
  { id: 12, icon: processingIcon, text: CUSTOMS_VALIDATION_IN_PROGRESS },
  { id: 13, animation: <SyncingIndicator />, text: CUSTOMS_NOTIFICATIONS_SYNCHRONIZATION },
];

const DECLARATION_STATUS_DATA = [
  { id: 0, icon: notSentIcon, text: NO_ANOMALIES_NO_RISK_ANALYSIS_DONE },
  { id: 1, icon: anomaliesHSCodeOrDescriptionIcon, text: ANOMALIES_DETECTED_HS_CODES },
  { id: 2, icon: anomalyValueIcon, text: ANOMALIES_DETECTED_VALUE },
  { id: 3, icon: anomaliesHandledIcon, text: ANOMALIES_DETECTED_ALL_HANDLED_BY_USER },
  { id: 4, icon: noSentAnomaliesIcon, text: NO_ANOMALIES_DETECTED },
];

const GOOD_ITEMS_STATUS_DATA = [
  { id: 0, icon: notSentIcon, text: NO_ANOMALIES_NO_RISK_ANALYSIS },
  { id: 1, icon: anomaliesHSCodeOrDescriptionIcon, text: ANOMALIES_DETECTED_HS_CODES },
  { id: 2, icon: anomalyValueIcon, text: ANOMALIES_DETECTED_VALUE },
  { id: 3, icon: anomaliesHandledIcon, text: ANOMALIES_DETECTED_NO_ACTION_REQUIRED },
  { id: 4, icon: noSentAnomaliesIcon, text: NO_ANOMALIES_DETECTED },
];

const ACTIONS_DATA = [
  { id: 0, icon: sendIcon, text: SEND },
  { id: 1, icon: actionsIcon, text: ACTIONS },
  { id: 2, icon: deleteIcon, text: DELETE },
  { id: 3, icon: archiveIcon, text: ARCHIVE_UNARCHIVE },
  { id: 4, icon: viewIcon, text: VIEW_DETAILS },
  { id: 5, icon: mailIcon, text: SEND_BY_EMAIL },
  { id: 6, icon: updateIcon, text: UPDATE },
  { id: 7, icon: downloadIcon, text: DOWNLOAD_FILE },
  { id: 8, icon: reportIcon, text: GENERATE_REPORT },
  { id: 9, icon: refactorIcon, text: EDIT },
  { id: 10, icon: viewImageIcon, text: VIEW_IMAGE },
  { id: 11, icon: customsSyncIcon, text: CUSTOMS_SYNC },
  { id: 12, icon: riskAnalysisReportIcon, text: RISK_ANALYSIS_REPORT },
  { id: 13, icon: sendRiskAnalysisToCustomerIcon, text: SEND_RISK_ANALYSIS_TO_CUSTOMER },
];

const renderBatchStatus = BATCH_STATUS_DATA.map((batchStatus) => {
  const { id, animation, icon, text } = batchStatus;

  return (
    <CategoryContainer key={id}>
      {animation && animation}
      {icon && <CategoryImage src={icon} alt={text} />}
      <CategoryText>{text}</CategoryText>
    </CategoryContainer>
  );
});

const renderDeclarationStatus = DECLARATION_STATUS_DATA.map((batchStatus) => {
  const { id, icon, text } = batchStatus;

  return (
    <CategoryContainer key={id} noMarginRight noFixedWidth>
      <CategoryImage src={icon} alt={text} />
      <CategoryText>{text}</CategoryText>
    </CategoryContainer>
  );
});

const renderGoodItemsStatus = GOOD_ITEMS_STATUS_DATA.map((batchStatus) => {
  const { id, icon, text } = batchStatus;

  return (
    <CategoryContainer key={id} noMarginRight noFixedWidth>
      <CategoryImage src={icon} alt={text} />
      <CategoryText>{text}</CategoryText>
    </CategoryContainer>
  );
});

const renderActions = ACTIONS_DATA.map((action) => {
  const { id, icon, text } = action;

  return (
    <CategoryContainer key={id}>
      <CategoryImage src={icon} alt={text} />
      <CategoryText>{text}</CategoryText>
    </CategoryContainer>
  );
});

const Help: FC = () => {
  return (
    <PageContainer isRelative overflowY>
      <PageHeader>
        <PageTitle>{HELP}</PageTitle>
      </PageHeader>
      <HelpContainer>
        <RowContainer>
          <SectionContainer fullWidth>
            <p>{BATCH_STATUS}</p>
            <CategoriesContainer flexRow>{renderBatchStatus}</CategoriesContainer>
          </SectionContainer>
        </RowContainer>
        <RowContainer>
          <SectionContainer>
            <p>{DECLARATION_STATUS}</p>
            <CategoriesContainer>{renderDeclarationStatus}</CategoriesContainer>
          </SectionContainer>
          <SectionContainer>
            <p>{GOOD_ITEMS_STATUS}</p>
            <CategoriesContainer>{renderGoodItemsStatus}</CategoriesContainer>
          </SectionContainer>
        </RowContainer>
        <RowContainer>
          <SectionContainer fullWidth>
            <p>{ACTIONS}</p>
            <CategoriesContainer flexRow>{renderActions}</CategoriesContainer>
          </SectionContainer>
        </RowContainer>
      </HelpContainer>
    </PageContainer>
  );
};

export default Help;
