import styled from 'styled-components';

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  * + * {
    margin-left: 10px;
  }
`;
