/* eslint-disable no-console */
/* eslint-disable max-statements */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

/* eslint-disable no-param-reassign */
import { Customer, CustomerDeclarant, RequestFilters } from '@e-origin/shared';

import { request } from '../utils';
import { AppThunk, RootState } from './index';

interface ICreateNewCustomer {
  declarant: CustomerDeclarant;
  name: Customer['name'];
  payments: Customer['payments'];
  representative: Customer['representative'];
  evaluationMethods: Customer['evaluationMethods'];
}

interface CustomersState {
  list: Customer[];
  loadingDetails: boolean;
  loadingList: boolean;
  selectedCustomer: Customer;
  totalCustomers: number;
}

const initialState: CustomersState = {
  list: [],
  loadingList: false,
  loadingDetails: false,
  selectedCustomer: {} as Customer,
  totalCustomers: 0,
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setList: (state: CustomersState, action: PayloadAction<Customer[]>) => {
      state.list = action.payload.map((item: any) => ({
        ...item,
        updatedAt: moment(item.updatedAt).format('DD-MM-YY @ HH:mm'),
      }));
      state.loadingList = false;
    },
    setTotalItems: (state: CustomersState, action: PayloadAction<number>) => {
      state.totalCustomers = action.payload;
    },
    setLoadingList: (state: CustomersState, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    clearCustomerDetails: (state: CustomersState) => {
      state.selectedCustomer = {} as Customer;
    },
    setCustomerDetails: (state: CustomersState, action: PayloadAction<Customer>) => {
      state.selectedCustomer = action.payload;
    },
    setLoadingDetails: (state: CustomersState, action: PayloadAction<boolean>) => {
      state.loadingDetails = action.payload;
    },
  },
});

export const { clearCustomerDetails, setCustomerDetails, setList, setLoadingDetails, setLoadingList, setTotalItems } =
  customersSlice.actions;

export const selectCustomers = (state: RootState) => state.customers.list;

export const selectCustomerDetails = (state: RootState) => state.customers.selectedCustomer;

export const selectTotalCustomersCount = (state: RootState) => state.customers.totalCustomers;

export const selectCustomersLoading = (state: RootState) => state.customers.loadingList;

export const selectCustomersNamesAndIds = (state: RootState) =>
  state.customers.list.map((customer: any) => {
    return {
      label: customer.name || '',
      value: customer._id || '',
    };
  });

export const selectCustomerById = (state: RootState, id: string) =>
  state.customers.list.find((customer: any) => customer._id === id);

export const setCustomersLoading = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  dispatch(setLoadingList(true));
};

export const fetchCustomers =
  (filters?: RequestFilters, page?: number, pageSize?: number): AppThunk =>
  async (dispatch: any, getState: any) => {
    try {
      const dataObject = {
        page,
        pageSize,
      };
      filters = filters || {};
      filters.declarantId = getState().declarant._id;

      const {
        data: { list, totalCustomers },
      } = await request({
        path: `customers`,
        method: 'POST',
        authenticate: true,
        dataObject: filters ? { ...dataObject, filters } : dataObject,
      });
      dispatch(setList(list));
      dispatch(setTotalItems(totalCustomers));
      dispatch(setLoadingList(false));
    } catch (error) {
      dispatch(setLoadingList(false));
      console.error(error);
      toast.error('Error fetching the customers!');
    }
  };

export const fetchCustomerDetails =
  (customerId: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(setLoadingDetails(true));
    try {
      const { data } = await request({
        path: `customers/${customerId}`,
        method: 'GET',
        authenticate: true,
      });
      dispatch(setLoadingDetails(false));
      dispatch(setCustomerDetails(data));
    } catch (error) {
      dispatch(setLoadingDetails(false));
      console.error(error);
      toast.error('Error fetching the customer!');
    }
  };

export const editCustomerDetails =
  (customerData: Partial<Customer>, customerId: Customer['_id']): AppThunk =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: `customers/${customerId}`,
        method: 'PATCH',
        authenticate: true,
        dataObject: customerData,
      });

      dispatch(setCustomerDetails(data));

      toast.success('Customer was updated!');
    } catch (error) {
      console.error(error);
      toast.error('Error updating the customer!');
    }
  };

export const createNewCustomer =
  (
    customerData: ICreateNewCustomer,
    navigationCallback: (customerId: Customer['_id']) => void,
  ): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: 'customers/add',
        method: 'POST',
        authenticate: true,
        dataObject: customerData,
      });

      dispatch(setCustomerDetails(data));
      navigationCallback(data._id);
      toast.success('New customer successfully created!');
    } catch (error) {
      const axiosError = error as AxiosError;

      console.error(error);
      toast.error(axiosError.response?.data.message || 'Error creating the customer!');
    }
  };

export const deleteCustomer =
  (customerId: string): AppThunk =>
  async (dispatch: any) => {
    try {
      await request({
        path: `customers/${customerId}`,
        method: 'DELETE',
        authenticate: true,
      });

      dispatch(setCustomersLoading());
      dispatch(fetchCustomers());
      toast.success('Customer successfully deleted!');
    } catch (error) {
      console.error(error);
      toast.error('Error deleting the customer!');
    }
  };

export const retrieveExternalRestrictedUsers = async (fullName: string) => {
  try {
    const { users } = await request({
      path: `customers/retrieve-external-restricted-users`,
      method: 'POST',
      authenticate: true,
      dataObject: {
        fullName,
      },
    });
    return users;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default customersSlice.reducer;
