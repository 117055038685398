/* eslint-disable max-statements */
import { Importer } from '@e-origin/shared';
import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { CustomTableActionsComponent } from '../../components';
import { TableActionsIcons } from '../../assets';
import ImportersStatusIndicator from './importers-status-indicator/importers-status-indicator.components';
import { useHistory } from 'react-router-dom';

export const useImportersTableColumns = () => {
  const history = useHistory();
  const commonActions = (row: Importer) => {
    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'View details',
        onClick: () => {
          history.push(`/importers/${row._id}`);
        },
      },
    ];
  };

  const buildColumns = (): IDataTableColumn<Importer>[] => [
    {
      name: 'Status',
      width: '100px',
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          <ImportersStatusIndicator status={row.status} />
        </div>
      ),
    },
    {
      name: 'Importer Name',
      width: '280px',
      cell: (row) => <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">{row.name}</div>,
    },
    {
      name: 'EORI number',
      width: '280px',
      cell: (row) => <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">{row.eori.number}</div>,
    },
    {
      name: 'VAT number',
      width: '250px',
      cell: (row) => <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">{row.vat.number}</div>,
    },
    {
      name: 'Date of last update',
      width: '300px',
      cell: (row) => <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">{row.updatedAt}</div>,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={[...commonActions(row)]}
          secondaryActions={[]}
          onClick={() => console.log(row)}
          disableAfterClick={true}
        />
      ),
    },
  ];

  const columns = useRef<IDataTableColumn<Importer>[]>(buildColumns());

  useEffect(() => {
    columns.current = buildColumns();
  });

  return columns;
};
