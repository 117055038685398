/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Declaration, EnvironmentCountries } from '@e-origin/shared';
import { selectDeclarant } from '../../../stores/declarantSlice';
import { retrieveCodes } from '../../../stores/settingsSlice';

import { StatusIcons } from '../../../assets';
import { Autocomplete, Button, Input, Switcher } from '../../../components';
import {
  checkImporterEori,
  checkIntracomVat,
  saveDeclarationDataLRN,
  savePartialDataToDeclaration,
  selectDetails,
} from '../../../stores/declarationsSlice';
import { BuyerForm, DeclarantForm, SellerForm } from '../../../components/declaration-details';
import { useConfig } from '../../../hooks/use-config';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';

const getRepresantativeData = (declarationDetails: Declaration, isHighValueNL: boolean, field: string): string => {
  if (isHighValueNL) {
    return declarationDetails.customer?.declarant[field];
  }

  const customsRepresentativeField = declarationDetails.customsReport?.declarant.representative[field];
  const customerRepresentativeField = declarationDetails.customer?.representative[field];

  return customsRepresentativeField || customerRepresentativeField;
};

const getRepresantativeAddressData = (
  declarationDetails: Declaration,
  isHighValueNL: boolean,
  field: string,
): string => {
  if (isHighValueNL) {
    return declarationDetails.customer?.declarant.address[field];
  }

  const customsRepresentativeField = declarationDetails.customsReport?.declarant.representative.address[field];
  const customerRepresentativeField = declarationDetails.customer?.representative.address[field];

  return customsRepresentativeField || customerRepresentativeField;
};

interface IDeclarationDetailsStakeholdersProps {
  editDisabled: boolean;
}

// eslint-disable-next-line max-statements
const DeclarationDetailsStakeholders: React.FC<IDeclarationDetailsStakeholdersProps> = (props) => {
  const dispatch = useDispatch();
  const declarationDetails = useSelector(selectDetails);

  const declarant = useSelector(selectDeclarant);

  const { config } = useConfig();

  const formik = useFormik({
    initialValues: {
      stakeholders: {
        exporter: {
          header: declarationDetails?.stakeholders?.exporter?.header,
          name: declarationDetails?.stakeholders?.exporter.name || '',
          identificationNumber: declarationDetails?.stakeholders?.exporter?.identificationNumber || '',
          address: {
            country: declarationDetails?.stakeholders?.exporter?.address?.country || '',
            street: declarationDetails?.stakeholders?.exporter?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.exporter?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.exporter?.address?.city || '',
          },
        },
        importer: {
          name: declarationDetails?.stakeholders?.importer?.name || '',
          identificationNumber: declarationDetails?.stakeholders?.importer?.identificationNumber || '',
          address: {
            street: declarationDetails?.stakeholders?.importer?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.importer?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.importer?.address?.city || '',
            country: declarationDetails?.stakeholders?.importer?.address?.country || '',
          },
        },
        declarant: {
          name: declarationDetails?.stakeholders?.declarant?.name || '',
          identificationNumber: declarationDetails?.stakeholders?.declarant?.identificationNumber || '',
          address: {
            street: declarationDetails?.stakeholders?.declarant?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.declarant?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.declarant?.address?.city || '',
            country: declarationDetails?.stakeholders?.declarant?.address?.country || '',
          },
          contactPerson: {
            name: declarationDetails?.stakeholders?.declarant?.contactPerson?.name || '',
            phoneNumber: declarationDetails?.stakeholders?.declarant?.contactPerson?.phoneNumber || '',
            email: declarationDetails?.stakeholders?.declarant?.contactPerson?.email || '',
          },
        },
        intracom: {
          name: declarationDetails?.stakeholders?.intracom?.name,
          identificationNumber: declarationDetails?.stakeholders?.intracom?.identificationNumber,
          address: {
            street: declarationDetails?.stakeholders?.intracom?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.intracom?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.intracom?.address?.city || '',
            country: declarationDetails?.stakeholders?.intracom?.address?.country || '',
          },
        },
        additionalSupplyChainActor: {
          header: declarationDetails?.stakeholders?.additionalSupplyChainActor?.header,
          name: declarationDetails?.stakeholders?.additionalSupplyChainActor?.name,
          identificationNumber: declarationDetails?.stakeholders?.additionalSupplyChainActor?.identificationNumber,
          role: declarationDetails?.stakeholders?.additionalSupplyChainActor?.role,
          address: {
            street: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.street || '',
            postcode: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.postcode || '',
            city: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.city || '',
            country: declarationDetails?.stakeholders?.additionalSupplyChainActor?.address?.country || '',
          },
        },
      },
      dv1: {
        seller: {
          header: declarationDetails?.dv1?.seller?.header,
          name: declarationDetails?.dv1?.seller?.name || '',
          identificationNumber: declarationDetails?.dv1?.seller?.identificationNumber || '',
          address: {
            street: declarationDetails?.dv1?.seller?.address?.street || '',
            city: declarationDetails?.dv1?.seller?.address?.city || '',
            postcode: declarationDetails?.dv1?.seller?.address?.postcode || '',
            country: declarationDetails?.dv1?.seller?.address?.country || '',
          },
        },
        buyer: {
          header: declarationDetails?.dv1?.buyer?.header,
          identificationNumber: declarationDetails?.dv1?.buyer?.identificationNumber || '',
          name: declarationDetails?.dv1?.buyer?.name || '',
          address: {
            street: declarationDetails?.dv1?.buyer?.address?.street || '',
            city: declarationDetails?.dv1?.buyer?.address?.city || '',
            postcode: declarationDetails?.dv1?.buyer?.address?.postcode || '',
            country: declarationDetails?.dv1?.buyer?.address?.country || '',
          },
        },
      },
    },
    onSubmit: async (values) => {
      dispatch(
        declarationDetails.generalInfo.group === 'H1'
          ? saveDeclarationDataLRN({
              lrn: declarationDetails.messageInfo.LRN,
              sequence: declarationDetails.goodShipmentGlobal.sequence,
              partialDeclaration: values,
            })
          : savePartialDataToDeclaration(declarationDetails._id, values),
      );
    },
    enableReinitialize: false,
  });

  const verifyIntracomVat = async () => {
    dispatch(
      checkIntracomVat(declarationDetails.messageInfo.LRN, formik.values.stakeholders.intracom.identificationNumber),
    );
  };

  const verifyImporterEori = async () => {
    dispatch(
      checkImporterEori(declarationDetails.messageInfo.LRN, formik.values.stakeholders.importer.identificationNumber),
    );
  };

  const [importerEoriStatus, setImporterEoriStatus] = useState({ value: undefined, icon: StatusIcons.NOT_SENT });
  const [intracomVatStatus, setIntracomVatStatus] = useState({ value: undefined, icon: StatusIcons.NOT_SENT });

  useEffect(() => {
    if (declarationDetails.stakeholders.importer.evalEORI?.eoriOk === undefined) {
      setImporterEoriStatus({ value: undefined, icon: StatusIcons.NOT_SENT });
    } else if (declarationDetails.stakeholders.importer.evalEORI?.eoriOk) {
      setImporterEoriStatus({ value: true, icon: StatusIcons.RELEASED });
    } else {
      setImporterEoriStatus({ value: false, icon: StatusIcons.FAILED });
    }

    if (declarationDetails.stakeholders.intracom?.evalVAT?.vatOk === undefined) {
      setIntracomVatStatus({ value: undefined, icon: StatusIcons.NOT_SENT });
    } else if (declarationDetails.stakeholders.intracom?.evalVAT?.vatOk) {
      setIntracomVatStatus({ value: true, icon: StatusIcons.RELEASED });
    } else {
      setIntracomVatStatus({ value: false, icon: StatusIcons.FAILED });
    }
  }, [declarationDetails]);

  const isHighValue = declarationDetails?.generalInfo?.group === 'H1';
  const isHighValueNL = config?.COUNTRY === EnvironmentCountries.NL && isHighValue;

  return (
    <FormContainer paddingRight={30} onSubmit={formik.handleSubmit}>
      <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
        <FormSectionTitle spaceBetween>
          Exporter{' '}
          <Switcher
            onChange={(active) => formik.setFieldValue('stakeholders.exporter.header', active)}
            active={formik.values.stakeholders.exporter.header}
            label="Header"
            disabled={props.editDisabled}
          />
        </FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.exporter.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.name}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.exporter.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="stakeholders.exporter.address.street"
            placeholder="Street and number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.address.street}
            width={40}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.exporter.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.address.postcode}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.exporter.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.exporter.address.city}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.exporter.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.exporter.address.country,
              label: formik.values.stakeholders.exporter.address.country,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      {isHighValueNL && <SellerForm formik={formik} editDisabled={props.editDisabled} />}
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Importer</FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.importer.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.name}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.importer.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.identificationNumber}
            width={50}
            widthUnit="%"
            {...(declarationDetails.generalInfo.group === 'H1'
              ? {
                  picture: importerEoriStatus.icon,
                  isShowPicture: true,
                  isShowPictureDisabled: true,
                  onPictureClick: verifyImporterEori,
                }
              : {})}
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="stakeholders.importer.address.street"
            placeholder="Street and number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.address.street}
            width={40}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.importer.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.address.postcode}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.importer.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.importer.address.city}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.importer.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.importer.address.country,
              label: formik.values.stakeholders.importer.address.country,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      {isHighValueNL && <BuyerForm formik={formik} editDisabled={props.editDisabled} />}
      {!isHighValueNL && (
        <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
          <FormSectionTitle>Intracom</FormSectionTitle>
          <FormRow>
            <Input
              name="stakeholders.intracom.name"
              placeholder="Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stakeholders.intracom.name}
              width={50}
              widthUnit="%"
              disabled={props.editDisabled}
            />
            <Input
              name="stakeholders.intracom.identificationNumber"
              placeholder="Identification number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stakeholders.intracom.identificationNumber}
              width={50}
              widthUnit="%"
              {...(declarationDetails.generalInfo.group === 'H1'
                ? {
                    picture: intracomVatStatus.icon,
                    isShowPicture: true,
                    isShowPictureDisabled: true,
                    onPictureClick: verifyIntracomVat,
                  }
                : {})}
              disabled={props.editDisabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="stakeholders.intracom.address.street"
              placeholder="Street and number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stakeholders.intracom.address.street}
              width={40}
              widthUnit="%"
              disabled={props.editDisabled}
            />
            <Input
              name="stakeholders.intracom.address.postcode"
              placeholder="Postcode"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stakeholders.intracom.address.postcode}
              width={30}
              widthUnit="%"
              disabled={props.editDisabled}
            />
            <Input
              name="stakeholders.intracom.address.city"
              placeholder="City"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stakeholders.intracom.address.city}
              width={30}
              widthUnit="%"
              disabled={props.editDisabled}
            />
          </FormRow>
          <FormRow>
            <Autocomplete
              width={30}
              widthUnit="%"
              placeholder="Country"
              fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
              onChange={(selectedOption) =>
                formik.setFieldValue('stakeholders.intracom.address.country', selectedOption?.value)
              }
              value={{
                value: formik.values.stakeholders.intracom.address.country,
                label: formik.values.stakeholders.intracom.address.country,
              }}
              disabled={props.editDisabled}
            />
          </FormRow>
        </FormSection>
      )}

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder spaceBetween>
          Additional supply chain actor{' '}
          <Switcher
            onChange={(active) => formik.setFieldValue('stakeholders.additionalSupplyChainActor.header', active)}
            active={formik.values.stakeholders.additionalSupplyChainActor.header}
            label="Header"
            disabled={props.editDisabled}
          />
        </FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.additionalSupplyChainActor.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.name}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.additionalSupplyChainActor.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="stakeholders.additionalSupplyChainActor.address.street"
            placeholder="Street and number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.address.street}
            width={40}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.additionalSupplyChainActor.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.address.postcode}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="stakeholders.additionalSupplyChainActor.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.stakeholders.additionalSupplyChainActor.address.city}
            width={30}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.additionalSupplyChainActor.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.additionalSupplyChainActor.address.country,
              label: formik.values.stakeholders.additionalSupplyChainActor.address.country,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Role"
            fetchOptions={(search: string) => retrieveCodes('CL704', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.additionalSupplyChainActor.role', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.additionalSupplyChainActor.role,
              label: formik.values.stakeholders.additionalSupplyChainActor.role,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder spaceBetween>
          Person providing a guarantee
        </FormSectionTitle>
        <FormRow>
          <Input
            placeholder="Identification number"
            value={declarationDetails.stakeholders?.personProvidingGuarantee?.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={true}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder spaceBetween>
          Person paying the customs duty
        </FormSectionTitle>
        <FormRow>
          <Input
            placeholder="Identification number"
            value={declarationDetails.stakeholders?.personPayingTheCustomDuty?.identificationNumber}
            width={50}
            widthUnit="%"
            disabled={true}
          />
        </FormRow>
      </FormSection>

      <DeclarantForm
        isHighValueNL={isHighValueNL}
        formik={formik}
        declarationDetails={declarationDetails}
        editDisabled={props.editDisabled}
      />

      <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
        <FormSectionTitle>Representative</FormSectionTitle>
        <FormRow>
          <Input
            disabled
            placeholder="Name"
            value={getRepresantativeData(declarationDetails, isHighValueNL, 'name')}
            width={33}
            widthUnit="%"
          />
          <Input
            disabled
            placeholder="Identification number"
            value={getRepresantativeData(declarationDetails, isHighValueNL, 'identificationNumber')}
            width={33}
            widthUnit="%"
          />
          <Input
            disabled
            placeholder="Status"
            value={declarationDetails.customer?.representative.status}
            width={33}
            widthUnit="%"
          />
        </FormRow>
        <FormRow>
          <Input
            disabled
            placeholder="Street and number"
            value={getRepresantativeAddressData(declarationDetails, isHighValueNL, 'street')}
            width={33}
            widthUnit="%"
          />
          <Input
            disabled
            placeholder="Postcode"
            value={getRepresantativeAddressData(declarationDetails, isHighValueNL, 'postcode')}
            width={33}
            widthUnit="%"
          />
          <Input
            disabled
            placeholder="City"
            value={getRepresantativeData(declarationDetails, isHighValueNL, 'city')}
            width={33}
            widthUnit="%"
          />
        </FormRow>
        <FormRow>
          <Input
            disabled
            placeholder="Country"
            value={getRepresantativeAddressData(declarationDetails, isHighValueNL, 'country')}
          />
        </FormRow>
      </FormSection>
      {!props.editDisabled && (
        <div>
          <Button type="submit" primary key="save-modal-btn">
            Save
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

export default DeclarationDetailsStakeholders;
