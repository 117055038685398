/* eslint-disable max-classes-per-file */
import { index, prop } from '@typegoose/typegoose';

import { BaseModel } from './base.model';
import { ConnectionTypes } from './connection.types';
import { DomainCollection } from './domain.collection';

export class Translations {
  @prop()
  en: string;

  @prop()
  fr: string;

  @prop()
  de: string;
}

@index({ 'translations.value': 1 })
@DomainCollection({ schemaOptions: { timestamps: true }, connectionName: ConnectionTypes.Catalogs })
export class MultilingualCode extends BaseModel {
  @prop({ _id: false, type: Translations })
  translations: Translations;

  @prop()
  family: string;

  @prop()
  value: string;
}
