/* eslint-disable max-statements */
import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Declaration } from '@e-origin/shared';

import { CustomTable, DeclarationModalEnum, DeclarationModalFactory } from '../../../components';
import {
  declarationGroupsFreeMemory,
  fetchDeclarationGroups,
  nextDeclarationGroups,
  selectDeclarationGroups,
  selectDeclarationGroupsFilters,
  selectDeclarationGroupsPagination,
  selectDeclarationGroupsSorting,
  selectDeclarationGroupsTotal,
  selectedDeclarationGroupsIsLoading,
  setDeclarationGroupsSorting,
} from '../../../stores/declarationGroupsSlice';
import { useDeclarationGroupsColumns } from './declaration-groups-table.columns';
import Cookies from 'js-cookie';
import { STORAGE_KEYS } from '../../../utils';

export const DeclarationGroupsTable: React.FC = () => {
  const dispatch = useDispatch();

  const filters = useSelector(selectDeclarationGroupsFilters);
  const sorting = useSelector(selectDeclarationGroupsSorting);
  const pagination = useSelector(selectDeclarationGroupsPagination);

  const declarationGroupsList = useSelector(selectDeclarationGroups);
  const totalDeclarationGroups = useSelector(selectDeclarationGroupsTotal);
  const isLoading = useSelector(selectedDeclarationGroupsIsLoading);

  const [showModalFactory, setShowModalFactory] = useState(false);
  const selectedModal = useRef<DeclarationModalEnum>(undefined);
  const selectedRow = useRef<Declaration>(null);

  const openModal = (row: Declaration, modal: DeclarationModalEnum) => {
    selectedModal.current = modal;
    selectedRow.current = row;
    setShowModalFactory(true);
  };

  useEffect(() => {
    // free memory when component is destroyed
    return () => {
      dispatch(declarationGroupsFreeMemory());
    };
  }, []);

  const fetchData = () => {
    dispatch(fetchDeclarationGroups());
  };

  const onSelectedRow = (row) => {
    selectedRow.current = row;
  };

  const columns = useDeclarationGroupsColumns({ openModal, fetchData, onSelectedRow });

  const handleSorting = (updatedSorting) => {
    dispatch(setDeclarationGroupsSorting(updatedSorting));
  };

  const handlePagination = (updatedPagination) => {
    Cookies.set(
      STORAGE_KEYS.COOKIES.LIST_DECLARATION_GROUPS_CRITERIA,
      JSON.stringify({ filters, sorting, pagination }),
    );

    dispatch(nextDeclarationGroups(updatedPagination));
  };

  return (
    <>
      <DeclarationModalFactory
        show={showModalFactory}
        selectedData={selectedRow.current}
        modal={selectedModal.current}
        onHide={() => setShowModalFactory(false)}
        onFinish={fetchData}
      />
      <CustomTable
        columns={columns.current}
        pagination={pagination}
        sorting={sorting}
        onSortingChange={handleSorting}
        onPaginationChange={handlePagination}
        data={declarationGroupsList || []}
        total={totalDeclarationGroups}
        isLoading={isLoading}
        disablePagination={isLoading}
        disableJumpToLastPage={true}
      />
    </>
  );
};
