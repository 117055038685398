import { EnvironmentCountries } from '@e-origin/shared';

export class Config {
  API_URL = `${/localhost/.test(window.location.href) ? 'http://localhost:3005' : ''}/api/v1`;

  DATA_TABLE_PAGE_SIZES = [10, 20, 50, 100];

  JWT_EXPIRATION_TIME = 4 * 3600;

  IS_PROD = !/(test.*\.eu)|localhost/.test(window.location.href);

  COUNTRY: EnvironmentCountries;

  async pullServerConfig() {
    try {
      const response = await fetch(`${this.API_URL}/config`);
      if (!response.ok) {
        throw new Error('Unable to fetch config!');
      }
      const data = await response.json();

      this.COUNTRY = data.country;
    } catch (error) {
      throw new Error('Fetch config error:', error);
    }
  }
}

let config: Config;

export const getConfig = async (): Promise<Config> => {
  if (!config) {
    config = new Config();
    await config.pullServerConfig();
  }
  return config;
};
