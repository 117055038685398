import { FC, TextareaHTMLAttributes, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Batch,
  DeclarationCustomsFlowType,
  DeclarationStatus,
  EnvironmentCountries,
  getTotal,
  TemplateType,
} from '@e-origin/shared';
import { Button, Modal } from '../../../components';
import { invalidateAllReleasedDeclarations } from '../../../stores/declarationsSlice';
import { InvalidationRequestForm } from '../../declaration-details/tabs/declaration-details-customs/declaration-details-customs.style';
import { Title } from './invalidate-batch-modal.styles';
import { useConfig } from '../../../hooks/use-config';

interface IInvalidateBatchModalProps {
  afterCreate(): void;
  onHide(): void;
  show: boolean;
  title: string;
  updatedBatch?: Batch;
}

const CANCEL = 'Cancel';
const INVALIDATE_BATCH = 'Invalidate batch';
const REQUEST_INVALIDATION = 'Request invalidation';

const getDeclarationsToInvalidate = (batch: Batch, country: EnvironmentCountries): JSX.Element => {
  const released = getTotal(batch, DeclarationStatus.RELEASED, DeclarationCustomsFlowType.POST);
  let totalAccepted = 0;

  if (country === EnvironmentCountries.NL) {
    const acceptedInPost = getTotal(batch, DeclarationStatus.ACCEPTED, DeclarationCustomsFlowType.POST);
    const acceptedInPre = getTotal(batch, DeclarationStatus.ACCEPTED, DeclarationCustomsFlowType.PRE);
    totalAccepted = acceptedInPost + acceptedInPre;
  }

  return (
    <div>
      <div>You are going to invalidate:</div>
      {released > 0 && (
        <div>
          {released} declaration(s) in status {DeclarationStatus.RELEASED}
        </div>
      )}
      {totalAccepted > 0 && (
        <div>
          {totalAccepted} declaration(s) in status {DeclarationStatus.ACCEPTED}
        </div>
      )}
      <div>
        Total {released + totalAccepted} of {batch.statistics.total}.
      </div>
    </div>
  );
};

const InvalidateBatchModal: FC<IInvalidateBatchModalProps> = (props) => {
  const { afterCreate, onHide, show, title, updatedBatch } = props;

  const dispatch = useDispatch();
  const { config } = useConfig();

  const [invalidationRequestText, setInvalidationRequestText] = useState('');

  const invalidateReleasedDeclarations = () => {
    dispatch(
      invalidateAllReleasedDeclarations(
        updatedBatch._id,
        invalidationRequestText,
        updatedBatch.templateType === TemplateType.HIGH_VALUE_H1,
      ),
    );
    afterCreate();
    onHide();
  };

  const handleInvalidateBatchChange: TextareaHTMLAttributes<HTMLTextAreaElement>['onChange'] = (event) => {
    setInvalidationRequestText(event.target.value);
  };

  return (
    <Modal
      title={title}
      onHide={onHide}
      show={show}
      buttons={[
        <Button type="button" key="cancel-batch-modal-btn" onClick={onHide}>
          {CANCEL}
        </Button>,
        <Button type="button" primary key="create-batch-modal-btn" onClick={invalidateReleasedDeclarations}>
          {REQUEST_INVALIDATION}
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <InvalidationRequestForm>
        <Title>{INVALIDATE_BATCH}</Title>
        {getDeclarationsToInvalidate(updatedBatch, config?.COUNTRY)}
        <textarea value={invalidationRequestText} onChange={handleInvalidateBatchChange} disabled={false} />
      </InvalidationRequestForm>
    </Modal>
  );
};

export default InvalidateBatchModal;
