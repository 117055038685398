import { ConnectionTypes } from './connection.types';
import { Declaration } from './declaration';
import { DomainCollection } from './domain.collection';

// @atlasSearchIndex({
//   name: 'transport',
//   mappings: {
//     fields: {
//       messageInfo: {
//         fields: {
//           transport: {
//             type: 'string',
//           },
//         },
//       },
//     },
//   },
//   searchAnalyzer: AtlasSearchAnalyzers.KEYWORD,
// })
@DomainCollection({
  schemaOptions: { timestamps: true },
  options: {
    customName: 'declarations',
  },
  connectionName: ConnectionTypes.Archiver,
})
export class DeclarationArchiver extends Declaration {}
