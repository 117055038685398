/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import remove from 'lodash/remove';
import moment from 'moment';
import numeral from 'numeral';
import { toast } from 'react-toastify';

/* eslint-disable no-param-reassign */
import {
  Batch,
  BatchCreate,
  BatchStatusEnum,
  BatchTypeEnum,
  Declaration,
  DeclarationCustomsFlowType,
  DeclarationGoods,
  IAdditionalCodesItemReturn,
  IBatchesCriteria,
  ICommodityCodeData,
  RecursivePartial,
  SortDirection,
  Template,
  TemplateType,
  User,
} from '@e-origin/shared';

import { IDropDownOption } from '../interfaces/dropdown-option.interface';
import { IDeclarationsForUpdateData } from '../pages/batches/bulk-update-batch-modal/bulk-update-batch-modal.component';
import { removeUndefined, request, STORAGE_KEYS } from '../utils';
import { AppThunk, RootState } from './index';

interface IBatchesState {
  list: Batch[];
  users: User[];
  templates: (IDropDownOption & { type: TemplateType; isExport?: boolean })[];
  loading: boolean;
  pageLoading: boolean;
  totalBatches: number;
  refreshBatches: number;
  filters: IBatchesCriteria['filters'];
  sorting: IBatchesCriteria['sorting'];
  pagination: IBatchesCriteria['pagination'];
}

interface IGenericBatchStatusMessage {
  batchId: string;
  status: string;
  statistics?: any;
  details?: string;
}

interface IBatchProgressMessage {
  batchId: string;
  status: string;
  value: number;
}

interface IScreenshotProgressMessage {
  batchId: string;
  status: string;
  totalWebUrls: number;
  processedUrls: number;
}

interface ISendAllDeclarationsToIDMSParams {
  batchId: string;
  movementReferenceNumber?: string;
  flow?: DeclarationCustomsFlowType;
}

const defaultBatchesCriteria: IBatchesCriteria = {
  filters: {
    filterByUsers: [],
  },
  pagination: { page: 1, size: 10, direction: 1, searchToken: '', currentToken: '' },
  sorting: { field: 'counter', direction: SortDirection.DESC },
};

const storedCriteria = () => {
  const criteria =
    (JSON.parse(Cookies.get(STORAGE_KEYS.COOKIES.LIST_BATCHES_CRITERIA) || null) as IBatchesCriteria) ||
    defaultBatchesCriteria;

  const batchView = Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum;
  if (!batchView || [BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(batchView)) {
    criteria.filters.templateType = TemplateType.HIGH_VALUE_H1;
  } else {
    criteria.filters.templateType = TemplateType.LOW_VALUE_H7;
  }
  return criteria;
};

const initialState: IBatchesState = {
  list: [],
  users: [],
  templates: [],
  loading: false,
  pageLoading: false,
  totalBatches: 0,
  refreshBatches: Date.now(),
  ...storedCriteria(),
};

export const batchesSlice = createSlice({
  name: 'batches',
  initialState,
  reducers: {
    setUsers: (state: IBatchesState, action: PayloadAction<User[]>) => {
      state.users = action.payload.map((item: any) => ({ ...item }));
    },
    setFilters: (state: IBatchesState, action: PayloadAction<IBatchesCriteria['filters']>) => {
      state.filters = { ...action.payload };
    },
    setSorting: (state: IBatchesState, action: PayloadAction<IBatchesCriteria['sorting']>) => {
      state.sorting = { ...action.payload };
    },
    setPagination: (state: IBatchesState, action: PayloadAction<IBatchesCriteria['pagination']>) => {
      state.pagination = { ...action.payload };
    },
    setList: (state: IBatchesState, action: PayloadAction<Batch[]>) => {
      state.list = action.payload.map((item: any) => ({
        ...item,
        template:
          state.templates.find((template: any) => template.value.toString() === item.template.toString())?.label ||
          'N/A',
        updatedAt: moment(item.updatedAt).format('DD-MM-YY @ HH:mm'),
      }));
      state.loading = false;
    },
    setTotalItems: (state: IBatchesState, action: PayloadAction<number>) => {
      state.totalBatches = action.payload;
    },
    setTemplates: (state: IBatchesState, action: PayloadAction<Template[]>) => {
      state.templates = action.payload
        .filter((t: any) => t.type !== TemplateType.INVOICE && t.type !== TemplateType.CUSTOMS_REPORT)
        .map((item: any) => ({
          value: item._id,
          label: item.name,
          type: item.type,
          isExport: item.isExport,
        })) as any;
    },
    setAllDeclarationsSent: (state: IBatchesState, action: PayloadAction<string>) => {
      state.list = state.list.map((item: Batch) => {
        if (item._id === action.payload) {
          return { ...item, status: { ...item.status, value: 'sending' }, allDeclarationsSent: true };
        }
        return item;
      });
    },
    deleteBatchFromList: (state: IBatchesState, action: PayloadAction<string>) => {
      const currentList = state.list.slice();
      remove(currentList, (item: Batch) => item._id === action.payload);
      state.list = currentList;
    },
    updateBatchStatus: (state: IBatchesState, action: PayloadAction<IGenericBatchStatusMessage>) => {
      const currentList = state.list.slice();
      let canUpdate = false;
      currentList.forEach((batch: Batch) => {
        if (batch._id === action.payload.batchId) {
          batch.status.value = action.payload.status;
          if (action.payload.details) {
            batch.status.details = action.payload.details;
          }
          if (action.payload.statistics) {
            batch.statistics = action.payload.statistics;
          }
          canUpdate = true;
        }
      });
      if (canUpdate) {
        state.list = currentList;
      }
    },
    updateBatchProgress: (state: IBatchesState, action: PayloadAction<IBatchProgressMessage>) => {
      const currentList = state.list.slice();
      let canUpdate = false;
      currentList.forEach((batch: Batch) => {
        if (batch._id === action.payload.batchId) {
          batch.progress = {
            status: action.payload.status,
            value: Math.ceil(action.payload.value),
          };

          canUpdate = true;
        }
      });
      if (canUpdate) {
        state.list = currentList;
      }
    },
    updateScrapingProgress: (state, action: PayloadAction<IBatchProgressMessage>) => {
      const currentList = state.list.slice();
      let canUpdate = false;
      currentList.forEach((batch) => {
        if (batch._id === action.payload.batchId) {
          const parsedValue = Math.ceil(action.payload.value);
          const scrapingDone = action.payload.status === 'scraping-finished' && parsedValue === 100;
          batch.status.value = scrapingDone ? 'success' : 'scraping';
          batch.progress = scrapingDone
            ? undefined
            : {
                status: action.payload.status,
                value: parsedValue,
              };
          canUpdate = true;
        }
      });
      if (canUpdate) {
        state.list = currentList;
      }
    },
    updateScreenshotProgress: (state: IBatchesState, action: PayloadAction<IScreenshotProgressMessage>) => {
      const currentList = state.list.slice();
      let canUpdate = false;
      currentList.forEach((batch: Batch) => {
        if (batch._id === action.payload.batchId) {
          const parsedValue =
            numeral(numeral((action.payload.processedUrls / action.payload.totalWebUrls) * 100).format('0')).value() ||
            0;
          if (parsedValue === 100 && batch.status.value !== 'success') {
            batch.status.value = 'success';
          }
          batch.progress =
            parsedValue === 100
              ? undefined
              : {
                  status: action.payload.status,
                  value: parsedValue,
                  processedUrls: action.payload.processedUrls,
                  totalWebUrls: action.payload.totalWebUrls,
                };
          canUpdate = true;
        }
      });
      if (canUpdate) {
        state.list = currentList;
      }
    },
    setLoading: (state: IBatchesState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageLoading: (state: IBatchesState, action: PayloadAction<boolean>) => {
      state.pageLoading = action.payload;
    },
    setBatchIsCleaning: (state: IBatchesState, action: PayloadAction<string>) => {
      const currentList = state.list.slice();
      currentList.forEach((batch: Batch) => {
        if (batch._id === action.payload) {
          batch.isCleaningUp = true;
        }
      });
    },
    updatedSyncingBatchesStatus: (state: IBatchesState, action: PayloadAction<any>) => {
      if (action.payload.batchesIds) {
        const currentList = state.list.slice();
        const batchesIdsToUpdated = action.payload.batchesIds.split(',');
        let canUpdate = false;
        currentList.forEach((batch: Batch) => {
          if (batchesIdsToUpdated.includes(batch._id)) {
            batch.status = {
              ...batch.status,
              value: 'success',
            };
            canUpdate = true;
          }
        });
        if (canUpdate) {
          state.list = currentList;
        }
      }
    },
    updateSendingStatus: (state: IBatchesState, action: PayloadAction<any>) => {
      const currentList = state.list.slice();
      let canUpdate = false;
      currentList.forEach((batch) => {
        if (batch._id === action.payload.batchId) {
          const parsedValue = Math.floor(100 * +action.payload.value);
          const done = parsedValue === 100;
          batch.status.value = done ? BatchStatusEnum.SUCCESS : BatchStatusEnum.SENDING;
          batch.progress = done
            ? undefined
            : {
                status: action.payload.status,
                value: parsedValue,
              };
          canUpdate = true;
        }
      });
      if (canUpdate) {
        state.list = currentList;
      }
    },
    updateInvalidatingReleasedStatus: (state: IBatchesState, action: PayloadAction<any>) => {
      const currentList = state.list.slice();
      let canUpdate = false;
      currentList.forEach((batch) => {
        if (batch._id === action.payload.batchId) {
          const parsedValue = Math.floor(100 * +action.payload.value);
          const done = parsedValue === 100;

          batch.progress = done
            ? undefined
            : {
                status: action.payload.status,
                value: parsedValue,
              };
          canUpdate = true;
        }
      });
      if (canUpdate) {
        state.list = currentList;
      }
    },
    updateSyncingStatus: (state: IBatchesState, action: PayloadAction<any>) => {
      const currentList = state.list.slice();
      let canUpdate = false;
      currentList.forEach((batch) => {
        if (batch._id === action.payload.batchId) {
          const parsedValue = Math.floor(100 * +action.payload.value);
          const done = parsedValue === 100;
          batch.status.value = done ? BatchStatusEnum.SUCCESS : BatchStatusEnum.SYNCING;
          batch.progress = done
            ? undefined
            : {
                status: action.payload.status,
                value: parsedValue,
              };
          canUpdate = true;
        }
      });
      if (canUpdate) {
        state.list = currentList;
      }
    },
  },
});

export const {
  setList,
  setUsers,
  setTotalItems,
  setLoading,
  setPageLoading,
  setTemplates,
  setAllDeclarationsSent,
  deleteBatchFromList,
  updateBatchStatus,
  updateBatchProgress,
  updateScrapingProgress,
  updateScreenshotProgress,
  setBatchIsCleaning,
  updatedSyncingBatchesStatus,
  updateSendingStatus,
  updateInvalidatingReleasedStatus,
  updateSyncingStatus,
} = batchesSlice.actions;

export const selectBatches = (state: RootState) => state.batches.list;

export const selectUsers = (state: RootState) => state.batches.users;

export const selectBatchesFilters = (state: RootState) => state.batches.filters;

export const selectBatchesSorting = (state: RootState) => state.batches.sorting;

export const selectBatchesPagination = (state: RootState) => state.batches.pagination;

export const selectTemplates = (state: RootState) => state.batches.templates;

export const selectTotalBatchesCount = (state: RootState) => state.batches.totalBatches;

export const selectBatchesLoading = (state: RootState) => state.batches.loading;

export const selectBatchesPageLoading = (state: RootState) => state.batches.pageLoading;

export const selectRefreshBatches = (state: RootState) => state.batches.refreshBatches;

export const clearBatches = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  dispatch(setTotalItems(0));
  dispatch(setList([]));
};

export const setBatchesFilters =
  (filters: Partial<IBatchesCriteria['filters']>): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const currentFilters = getState().batches.filters;

    const mergedFilters = removeUndefined({
      ...currentFilters,
      ...filters,
    });

    dispatch(batchesSlice.actions.setFilters(mergedFilters));
  };

export const setBatchesSorting =
  (sorting: IBatchesCriteria['sorting']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(batchesSlice.actions.setSorting(sorting));
  };

export const setBatchesPagination =
  (pagination: IBatchesCriteria['pagination']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(batchesSlice.actions.setPagination(pagination));
  };

export const setBatchesLoading = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  dispatch(setLoading(true));
};

export const fetchBatches =
  (options: { persistPagination: boolean } = { persistPagination: false }): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { filters, sorting, pagination } = getState().batches;
      dispatch(setLoading(true));
      const {
        data: { list, totalItems },
      } = await request({
        path: `batches`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria: {
            filters,
            sorting,
            pagination: options.persistPagination
              ? {
                  ...pagination,
                  direction: pagination.page > 1 ? pagination.direction : 1,
                  searchToken: pagination.currentToken || undefined,
                }
              : {
                  page: 1,
                  direction: 1,
                  size: pagination.size,
                },
          },
        },
      });

      if (!options.persistPagination) {
        dispatch(
          setBatchesPagination({
            page: 1,
            direction: 1,
            size: pagination.size,
            searchToken: list[0]?.paginationToken,
          }),
        );
      } else {
        dispatch(
          setBatchesPagination({
            ...pagination,
            searchToken: list[0]?.paginationToken,
          }),
        );
      }

      dispatch(setTotalItems(totalItems));
      dispatch(setList(list));
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
      toast.error('Error fetching the batches!');
    }
  };

export const fetchBatch = async (batchId: string) => {
  try {
    const { data } = await request({
      path: `batches/${batchId}`,
      method: 'GET',
      authenticate: true,
    });
    return data.batch;
  } catch (error) {
    console.error(error);
    toast.error('Error fetching the batch!');
  }
};

export const nextBatches =
  (newPagination: { page: number; size: number; direction: 1 | -1 }): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { filters, sorting, pagination } = getState().batches;

      dispatch(setLoading(true));
      dispatch(setPageLoading(true));

      const {
        data: { list, totalItems },
      } = await request({
        path: `batches`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria: {
            filters,
            sorting,
            pagination: {
              page: newPagination.page,
              searchToken: pagination.searchToken,
              direction: newPagination.direction,
              size: newPagination.size,
            },
          },
        },
      });

      dispatch(
        setBatchesPagination({
          page: newPagination.page,
          size: newPagination.size,
          direction: newPagination.direction,
          searchToken: list[0]?.paginationToken,
          currentToken: pagination.searchToken,
        }),
      );

      dispatch(setTotalItems(totalItems));
      dispatch(setList(list));
      dispatch(setLoading(false));
      dispatch(setPageLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setPageLoading(false));
      console.error(error);
      toast.error('Error fetching the batches!');
    }
  };

export const fetchUsers =
  (id: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const { data } = await request({
        path: `users/listByDeclarant/${id}`,
        method: 'GET',
        authenticate: true,
      });
      dispatch(setUsers(data));
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
      toast.error('Error fetching the list of users!');
    }
  };

export const saveBatchComment =
  (batchId: string, comment: string, status: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      await request({
        path: `batches/${batchId}/comment`,
        method: 'PATCH',
        authenticate: true,
        dataObject: {
          comment,
          status,
        },
      });
      toast.success('Comment was saved!');
    } catch (error) {
      console.error(error);
      toast.error('Error saving the comment!');
    }
  };

export const fetchTemplates = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  try {
    const { data } = await request({
      path: `template`,
      method: 'GET',
      authenticate: true,
    });
    dispatch(setTemplates(data));
  } catch (error) {
    console.error(error);
    toast.error('Error fetching the batches!');
  }
};

export const createNewBatch =
  (batch: BatchCreate): AppThunk<Promise<void>> =>
  async () => {
    try {
      const formData = new FormData();
      if (batch.file) {
        formData.append('file', batch.file);

        formData.append('name', batch.name);
        formData.append('customer', batch.customer);
        formData.append('template', batch.template);

        await request({
          path: 'batches/add',
          method: 'POST',
          authenticate: true,
          dataObject: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success(`New batch was uploaded, it's in the processing queue now!`);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        `Error uploading the batch! ${
          (error as any)?.response?.data?.message ? `(${(error as any).response.data.message})` : ''
        }`,
      );
    }
  };

export const updateBatch =
  (batch: BatchCreate, batchId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      const formData = new FormData();
      if (batch.file) {
        formData.append('file', batch.file);

        formData.append('name', batch.name);
        formData.append('customer', batch.customer);
        formData.append('template', batch.template);

        await request({
          path: `batches/${batchId}/update`,
          method: 'POST',
          authenticate: true,
          dataObject: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success(`New batch was uploaded, it's in the processing queue now!`);
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      console.error(error);
      toast.error(axiosError.response?.data.message || 'Error updating the batch!');
    }
  };

export const bulkUpdateBatch =
  (batchId: string, declarationsForUpdate: IDeclarationsForUpdateData[]): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      await request({
        path: `batches/${batchId}/bulk-update`,
        method: 'POST',
        authenticate: true,
        dataObject: { declarationsForUpdate },
      });

      dispatch(fetchBatches());
      toast.success(`The batch was updated, it's in the processing queue now!`);
    } catch (error) {
      const axiosError = error as AxiosError;

      console.error(error);
      toast.error(axiosError.response?.data.message || 'Error updating the batch!');
    }
  };

export const updateBatchStatistics =
  (batchId: string, declarationsIdsForUpdate: string[]): AppThunk<Promise<void>> =>
  async () => {
    try {
      await request({
        path: `batches/${batchId}/update-statistics`,
        method: 'POST',
        authenticate: true,
        dataObject: { declarationsIdsForUpdate },
      });
    } catch (error) {
      const axiosError = error as AxiosError;

      console.error(error);
      toast.error(axiosError.response?.data.message || 'Error updating statistics for batch!');
    }
  };

export const sendAllDeclarationsToPLDA =
  (batchId: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(setAllDeclarationsSent(batchId));
      await request({
        path: `batches/${batchId}/send-all-declarations-to-plda`,
        method: 'POST',
        authenticate: true,
      });
      toast.success('Started sending all declarations!');
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(error);
      let errorMessage = 'Error sending all declarations!';
      if (axiosError.response?.data.message && axiosError.response?.data.message.includes('Batch is already sending')) {
        errorMessage = 'Batch is already sending!';
      }
      toast.error(errorMessage);
    }
  };

export const sendAllDeclarationsToIDMS =
  ({ batchId, movementReferenceNumber, flow }: ISendAllDeclarationsToIDMSParams): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(setAllDeclarationsSent(batchId));
      await request({
        path: `idms/${batchId}/send-all-declarations`,
        method: 'POST',
        authenticate: true,
        dataObject: { movementReferenceNumber, flow },
      });
      toast.success('Started sending all declarations!');
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(error);
      let errorMessage = 'Error sending all declarations!';
      if (axiosError.response?.data.message && axiosError.response?.data.message.includes('Batch is already sending')) {
        errorMessage = 'Batch is already sending!';
      }
      toast.error(errorMessage);
    }
  };

export const sendBatchToBeGate =
  (batchId: string, lrn?: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(setAllDeclarationsSent(batchId));
      await request({
        path: `batches/${batchId}/send-declarations-be-gate/${lrn || ''}`,
        method: 'GET',
        authenticate: true,
      });
      toast.success('Started sending all declarations!');
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(error);
      let errorMessage = 'Error sending all declarations!';
      if (axiosError.response?.data.message && axiosError.response?.data.message.includes('Batch is already sending')) {
        errorMessage = 'Batch is already sending!';
      }
      toast.error(errorMessage);
    }
  };

export const finalizeDeclarations =
  (batchId: string, movementReferenceNumber?: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(setAllDeclarationsSent(batchId));
      await request({
        path: `batches/${batchId}/finalize-declarations`,
        method: 'POST',
        authenticate: true,
        dataObject: { movementReferenceNumber },
      });
      toast.success('Started finalizing all declarations!');
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error(error);
      toast.error(axiosError.response?.data.message);
    }
  };

export const retrieveAdditionalCodes = async (batchId: string) => {
  try {
    const {
      data,
    }: {
      data: IAdditionalCodesItemReturn[];
    } = await request({
      path: `batches/${batchId}/retrieve-additional-codes`,
      method: 'PATCH',
      authenticate: true,
    });
    toast.success('Started finalizing all declarations!');
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error(error);
    toast.error(axiosError.response?.data.message);
    return [];
  }
};

export const saveFinalizeDataToAllDeclarations = async (
  batchId: string,
  partialDeclaration: RecursivePartial<Declaration>,
  partialGoodsShipmentItem: RecursivePartial<DeclarationGoods>,
  commodityCodes: ICommodityCodeData[],
) => {
  try {
    await request({
      path: `batches/${batchId}/save-finalize-data-to-all-declarations`,
      method: 'PATCH',
      authenticate: true,
      dataObject: { partialDeclaration, partialGoodsShipmentItem, commodityCodes },
    });
    toast.success('Finalize data saved');
  } catch (error) {
    console.error(error);
    toast.error('Could not save finalize data');
    return null;
  }
};

export const retrieveFinalizeDeclarationsInfo = async (batchId: string) => {
  try {
    const data = await request({
      path: `batches/${batchId}/retrieve-finalize-declarations-info`,
      method: 'GET',
      authenticate: true,
    });

    return data.declaration;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const generateBatchReport =
  (batchId: string): AppThunk<Promise<void>> =>
  async () => {
    const toasterId = toast.info('Generating batch report..', {
      containerId: 'intrastToaster',
      autoClose: false,
      closeButton: false,
    });
    try {
      const response = await request(
        {
          path: `batches/${batchId}/generate-report`,
          method: 'POST',
          authenticate: true,
          fileDownload: true,
        },
        true,
      );

      const href = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;

      let filename = '';
      const disposition = response.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      filename = matches[1].replace(/['"]/g, '');

      link.setAttribute('download', filename); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.update(toasterId, {
        render: 'Successfully generated the batch report!',
        autoClose: 3000,
        closeButton: true,
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      toast.update(toasterId, {
        render: 'Error generating the batch report!',
        autoClose: 3000,
        closeButton: true,
        type: 'error',
      });
    }
  };

export const generateBatchRiskAnalysisReport =
  (batchId: string, fileName: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      const response = await request({
        path: `batches/${batchId}/generate-risk-analysis-report`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
      });
      const href = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = href;
      const reportFilename = `${fileName.split('.')[0]}-report.xlsx`;
      link.setAttribute('download', reportFilename); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Successfully generated the batch risk analysis report!');
    } catch (error) {
      console.error(error);
      toast.error('Error generating the batch risk analysis report!');
    }
  };

export const generateInvoice =
  (batchId: string, fileName: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      const response = await request({
        path: `batches/${batchId}/generate-invoice`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
      });
      const href = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = href;
      const reportFilename = `${fileName.split('.')[0]}-invoice.xlsx`;
      link.setAttribute('download', reportFilename); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Successfully generated the batch invoice!');
    } catch (error) {
      console.error(error);
      toast.error('Error generating the batch invoice!');
    }
  };

export const runRiskAnalysisCalculations =
  (batchId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      await request({
        path: `batches/${batchId}/run-risk-analysis-calculations`,
        method: 'GET',
        authenticate: true,
      });
      toast.success('Successfully runned the risk analysis calculations');
    } catch (error) {
      console.error(error);
      toast.error('Risk analysis calculations failed!');
      return null;
    }
  };

export const exportScreenshots =
  (batchId: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      const { data, headers } = await request(
        {
          path: `batches/${batchId}/export-screenshots`,
          method: 'POST',
          authenticate: true,
          responseType: 'blob',
        },
        true,
      );
      const href = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      const fileName = /filename="(.*)"/.exec(headers['content-disposition'])[1];
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Successfully downloaded the batch screenshots!');
    } catch (error) {
      console.error(error);
      toast.error('Error downloading the batch screenshots!');
    }
  };

export const getBatchFirstDeclaration = async (batchId: string) => {
  try {
    const response = await request({
      path: `batches/${batchId}/first-declaration`,
      method: 'POST',
      authenticate: true,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const downloadOriginalFileFromCloud =
  (batchId: string, fileName: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      const response = await request({
        path: `batches/${batchId}/download`,
        method: 'POST',
        authenticate: true,
        fileDownload: true,
      });
      const href = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Successfully downloaded the original file!');
    } catch (error) {
      console.error(error);
      toast.error('Error downloading the original file!');
    }
  };

export const deleteBatch =
  (batchId: string, softDelete?: boolean): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      await request({
        path: `batches/${batchId}`,
        method: 'DELETE',
        authenticate: true,
        dataObject: {
          softDelete,
        },
      });
      dispatch(deleteBatchFromList(batchId));
      toast.success('Successfully deleted the batch!');
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      let errorMessage = 'Error deleting batch!';
      if (
        axiosError.response?.data.message &&
        axiosError.response?.data.message.includes(`Couldn't find batch with id`)
      ) {
        errorMessage = 'Batch was already deleted!';
      }
      toast.error(errorMessage);
    }
  };

export const archiveBatch =
  (batchId: string, isUnarchive = false): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      await request({
        path: `batches/${batchId}/${isUnarchive ? 'un-' : ''}archive`,
        method: 'PUT',
        authenticate: true,
      });
      dispatch(deleteBatchFromList(batchId));
      toast.success('Successfully archived the batch!');
    } catch (error) {
      console.error(error);
      toast.error('Error archiving the batch!');
    }
  };

export const sendBatchRiskAnalysisReport =
  (batchId: string, transitionToInValidate = true): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      if (transitionToInValidate) {
        await request(
          {
            path: `batches/${batchId}/transition-to-invalidate`,
            method: 'POST',
            authenticate: true,
          },
          true,
        );
      }
      const { data, headers } = await request(
        {
          path: `batches/${batchId}/send-risk-analysis-report`,
          method: 'POST',
          authenticate: true,
          fileDownload: true,
        },
        true,
      );

      const href = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      const reportFilename = headers['content-disposition']?.match(/filename="([^"]*)"/)[1] || 'customer-report.xlsx';
      link.setAttribute('download', reportFilename); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success('Successfully sent the batch risk analysis report!');
      dispatch(fetchBatches());
    } catch (error) {
      console.error(error);
      toast.error('Error sending the batch risk analysis report!');
    }
  };

export const generateCustomerReport = (batchId: string): AppThunk<Promise<void>> =>
  sendBatchRiskAnalysisReport(batchId, false);

export const syncBatchWithCustoms =
  (batch: Batch): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      if (batch.templateType === TemplateType.HIGH_VALUE_H1) {
        await request({
          path: `external-notifications`,
          method: 'GET',
          authenticate: true,
        });
        // todo: we could make one single request, since we send only to one (PLDA or IDMS)
        await request({
          path: `idms/${batch._id}/customs-sync`,
          method: 'POST',
          authenticate: true,
        });
      } else {
        await request({
          path: `idms/${batch._id}/customs-sync`,
          method: 'POST',
          authenticate: true,
        });
      }

      await request({
        path: `batches/${batch.counter}/recount-statistics`,
        method: 'POST',
        authenticate: true,
      });

      dispatch(fetchBatches({ persistPagination: true }));
      toast.success('Batch is added to syncing queue.');
    } catch (error) {
      console.error(error);
      toast.error('Error syncing the batch!');
    }
  };

export const updateBatchStatusRealTime = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  dispatch(fetchBatches({ persistPagination: true }));
};

export const updateBatchProgressRealTime =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    if (data.status === 'batch-initialized') {
      dispatch(fetchBatches({ persistPagination: true }));
      return;
    }
    dispatch(updateBatchProgress(data));
  };

let scrapingStatus = '';

export const updateScrapingProgressRealTime =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const parsedData = data;
    dispatch(updateScrapingProgress(parsedData));

    if (scrapingStatus === 'scraping-in-progress' && parsedData.status === 'saving-db') {
      scrapingStatus = 'saving-db';
      dispatch(fetchBatches({ persistPagination: true }));
    }

    scrapingStatus = parsedData.status;
    if (parsedData.status === 'scraping-finished') {
      dispatch(fetchBatches({ persistPagination: true }));
    }
  };

export const updateScreenshotProgressRealTime =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(updateScreenshotProgress({ ...data, status: 'screenshot-in-progress' }));
  };

export const cleanupArchivedBatch =
  (batchId: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(setBatchIsCleaning(batchId));
    try {
      await request({
        path: `batches/${batchId}/cleanup`,
        method: 'POST',
        authenticate: true,
      });
      toast.success('Batch was cleaned successfully.');
    } catch (error) {
      console.error(error);
      toast.error('Error cleaning the batch!');
    }
  };

export const updatedSyncingBatchesStatusRealTime =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(updatedSyncingBatchesStatus(data));
  };

export const updatedSendingStatusRealTime =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(updateSendingStatus(data));
  };

export const updatedInvalidatingReleasedRealTime =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(updateInvalidatingReleasedStatus(data));
  };

export const updatedSyncingStatusRealTime =
  (data: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(updateSyncingStatus(data));
  };

/* NL DECO testing - to be removed - START */
export const generateNlDecoTestFile =
  (declarationId: string, messageType: string): AppThunk<Promise<void>> =>
  async () => {
    try {
      toast.success('File generation initiated!');
      const response = await request(
        {
          path: `idms/generate-test-deco-files/${declarationId}/${messageType}`,
          method: 'POST',
          authenticate: true,
          fileDownload: true,
        },
        true,
      );

      const binaryData = [];
      binaryData.push(response.data);
      const href = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/zip' }));
      const link = document.createElement('a');
      link.href = href;

      const [, filename] = response.headers['content-disposition'].match(/attachment; filename="(.*)"/i);

      link.setAttribute('download', filename); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  };
/* NL DECO testing - to be removed - END */

export default batchesSlice.reducer;
