/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import { useFormik } from 'formik';
import { cloneDeep, omit } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable no-use-before-define */
import { EORIGIN_DATE_FORMAT, GoodLocation } from '@e-origin/shared';

import { Autocomplete, Button, DatePicker, Input, InputHeader, MultipleContainers } from '../../../components';
import { selectDeclarant, selectDeclarantGoodsLocations } from '../../../stores/declarantSlice';
import { saveDeclarationDataLRN, savePartialDataToDeclaration, selectDetails } from '../../../stores/declarationsSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { retrieveCodes } from '../../../stores/settingsSlice';

interface IDeclarationDetailsStakeholdersProps {
  editDisabled: boolean;
}

const DeclarationDetailsGeneralInfo: React.FC<IDeclarationDetailsStakeholdersProps> = (props) => {
  const declarant = useSelector(selectDeclarant);
  const dispatch = useDispatch();
  const declarationDetails = useSelector(selectDetails);
  const goodsLocations = useSelector(selectDeclarantGoodsLocations) as GoodLocation[];

  const isExport = declarationDetails?.generalInfo?.isExport;

  const formik = useFormik({
    initialValues: {
      messageInfo: {
        declarationMainType: declarationDetails?.messageInfo?.declarationMainType,
        declarationType: declarationDetails?.messageInfo?.declarationType,
        acceptanceDate: declarationDetails?.messageInfo?.acceptanceDate,
      },
      consignment: {
        referenceNumberUCR: declarationDetails?.consignment?.referenceNumberUCR,
        referenceNumberUCRHeader: declarationDetails?.consignment?.referenceNumberUCRHeader,
        customsOfficePresentation: {
          issuingPlaceCode: declarationDetails?.consignment?.customsOfficePresentation?.issuingPlaceCode,
          referenceNumber: declarationDetails?.consignment?.customsOfficePresentation?.referenceNumber,
        },
        customsSupervisingOffice: {
          referenceNumber: declarationDetails?.consignment?.customsSupervisingOffice?.referenceNumber,
        },
        locationOfGoods: {
          unLocode: declarationDetails?.consignment?.locationOfGoods?.unLocode,
        },
        countryOfDispatch: declarationDetails?.consignment?.countryOfDispatch,
        countryOfDispatchHeader: declarationDetails?.consignment?.countryOfDispatchHeader,
        destinationHeader: declarationDetails?.consignment?.destinationHeader,
        countryOfDestination: declarationDetails?.consignment?.countryOfDestination,
        regionOfDestination: declarationDetails?.consignment?.regionOfDestination,
        customsOfficeExit: {
          referenceNumber: declarationDetails?.consignment?.customsOfficeExit?.referenceNumber,
        },
      },
      otherDataElements: {
        registrationNumber: declarationDetails?.otherDataElements?.registrationNumber,
        natureOfTransaction: declarationDetails?.otherDataElements?.natureOfTransaction,
        natureOfTransactionHeader: declarationDetails?.otherDataElements?.natureOfTransactionHeader,
      },
      goodShipmentGlobal: {
        customsProcedureType: declarationDetails?.goodShipmentGlobal?.customsProcedureType,
        customsNationalProcedure: declarationDetails?.goodShipmentGlobal?.customsNationalProcedure,
        customsProcedure: declarationDetails?.goodShipmentGlobal?.customsProcedure,
        customsNationalProcedureCode: declarationDetails?.goodShipmentGlobal?.customsNationalProcedureCode,
        preference: declarationDetails?.goodShipmentGlobal?.preference,
      },
      valuationInformationTaxes: {
        deliveryTerms: {
          incotermCode: declarationDetails?.valuationInformationTaxes?.deliveryTerms?.incotermCode,
          location: declarationDetails?.valuationInformationTaxes?.deliveryTerms?.location,
          country: declarationDetails?.valuationInformationTaxes?.deliveryTerms?.country,
        },
      },
      transportInformation: {
        inlandModeOfTransport: declarationDetails?.transportInformation?.inlandModeOfTransport,
        modeOfTransportMeansAtTheBorder: declarationDetails?.transportInformation?.modeOfTransportMeansAtTheBorder,
        arrivalTransportMeans: {
          typeOfIdentification: declarationDetails?.transportInformation?.arrivalTransportMeans?.typeOfIdentification,
          identificationNumber: declarationDetails?.transportInformation?.arrivalTransportMeans?.identificationNumber,
        },
        departureTransportMeans: {
          identificationNumber: declarationDetails.transportInformation?.departureTransportMeans?.identificationNumber,
        },
        activeBorderTransportMeans: {
          nationality: declarationDetails.transportInformation?.activeBorderTransportMeans?.nationality,
          identificationNumber:
            declarationDetails.transportInformation?.activeBorderTransportMeans?.identificationNumber,
        },
        transportEquipment: {
          header: declarationDetails.transportInformation?.transportEquipment?.header,
          containerIdentification: declarationDetails.transportInformation?.transportEquipment?.containerIdentification,
          containers: declarationDetails.transportInformation?.transportEquipment?.containers || [
            {
              identificationNumber:
                declarationDetails.transportInformation?.transportEquipment?.containerIdentification || '',
            },
          ],
          seals: declarationDetails.transportInformation?.transportEquipment?.seals?.length
            ? declarationDetails.transportInformation?.transportEquipment?.seals
            : [{ identificationNumber: '' }],
        },
        countryOfRoutingOfConsignment: declarationDetails.transportInformation?.countryOfRoutingOfConsignment?.length
          ? declarationDetails.transportInformation?.countryOfRoutingOfConsignment
          : [{ country: '' }],
      },
      declarationGoods: {
        packaging: {
          typeOfPackages: (declarationDetails.goodsShipmentItems || [])[0].packaging?.typeOfPackages,
        },
      },
    },
    onSubmit: async (values) => {
      const partialDeclaration = cloneDeep(omit(values, 'declarationGoods'));

      if (partialDeclaration.transportInformation?.transportEquipment?.seals?.length) {
        partialDeclaration.transportInformation.transportEquipment.seals =
          partialDeclaration.transportInformation.transportEquipment.seals.filter((s) => !!s.identificationNumber);
      }

      if (partialDeclaration.transportInformation?.transportEquipment?.containers.length) {
        partialDeclaration.transportInformation.transportEquipment.containers =
          partialDeclaration.transportInformation.transportEquipment.containers.filter((s) => !!s.identificationNumber);
      }

      if (partialDeclaration.transportInformation?.countryOfRoutingOfConsignment?.length) {
        partialDeclaration.transportInformation.countryOfRoutingOfConsignment =
          partialDeclaration.transportInformation.countryOfRoutingOfConsignment.filter((c) => !!c.country);
      }

      dispatch(
        declarationDetails.generalInfo.group === 'H1'
          ? saveDeclarationDataLRN({
              lrn: declarationDetails.messageInfo.LRN,
              sequence: declarationDetails.goodShipmentGlobal.sequence,
              partialDeclaration,
              partialGoodsShipmentItem: values.declarationGoods,
            })
          : savePartialDataToDeclaration(
              declarationDetails._id,
              omit(values, 'declarationGoods'),
              values.declarationGoods,
            ),
      );
    },
    enableReinitialize: false,
  });

  return (
    <FormContainer paddingRight={30} onSubmit={formik.handleSubmit}>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Global Parameter</FormSectionTitle>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Declaration Main Type"
            fetchOptions={(search: string) => retrieveCodes('CL231', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('messageInfo.declarationMainType', selectedOption?.value)
            }
            value={{
              value: formik.values.messageInfo.declarationMainType,
              label: formik.values.messageInfo.declarationMainType,
            }}
          />
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Declaration Type"
            fetchOptions={(search: string) => retrieveCodes('CL042', search, declarant.language)}
            onChange={(selectedOption) => formik.setFieldValue('messageInfo.declarationType', selectedOption?.value)}
            value={{
              value: formik.values.messageInfo.declarationType,
              label: formik.values.messageInfo.declarationType,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <InputHeader
            name="consignment.referenceNumberUCR"
            placeholder="Commercial reference"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.consignment.referenceNumberUCR}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
            onHeaderChange={(active) => formik.setFieldValue('consignment.referenceNumberUCRHeader', active)}
            headerValue={formik.values.consignment.referenceNumberUCRHeader}
          />
          <Input
            name="consignment.customsOfficePresentation.issuingPlaceCode"
            placeholder="Place of Issue"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.consignment.customsOfficePresentation.issuingPlaceCode}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>

        <FormRow>
          <Input
            name="otherDataElements.registrationNumber"
            placeholder="Registration number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.otherDataElements.registrationNumber}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />

          <DatePicker
            placeholder="Acceptance Date"
            width={50}
            widthUnit="%"
            value={
              formik.values.messageInfo.acceptanceDate
                ? moment(formik.values.messageInfo.acceptanceDate, EORIGIN_DATE_FORMAT).toDate()
                : null
            }
            onChange={(value: Date) =>
              formik.setFieldValue(
                'messageInfo.acceptanceDate',
                value ? moment(value, EORIGIN_DATE_FORMAT).format(EORIGIN_DATE_FORMAT) : null,
              )
            }
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Procedure</FormSectionTitle>
        <FormRow>
          <Input
            name="goodShipmentGlobal.customsProcedureType"
            placeholder="Procedure Type"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.goodShipmentGlobal.customsProcedureType}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Regulation Part 1"
            fetchOptions={(search: string) => retrieveCodes('CL092', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('goodShipmentGlobal.customsProcedure', selectedOption?.value)
            }
            value={{
              value: formik.values.goodShipmentGlobal.customsProcedure,
              label: formik.values.goodShipmentGlobal.customsProcedure,
            }}
            disabled={props.editDisabled}
          />
          <Input
            name="goodShipmentGlobal.customsNationalProcedure"
            placeholder="Regulation Part 2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.goodShipmentGlobal.customsNationalProcedure}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="National code"
            fetchOptions={(search: string) => retrieveCodes('CL603', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('goodShipmentGlobal.customsNationalProcedureCode', selectedOption?.value)
            }
            value={{
              value: formik.values.goodShipmentGlobal.customsNationalProcedureCode,
              label: formik.values.goodShipmentGlobal.customsNationalProcedureCode,
            }}
            disabled={props.editDisabled}
          />
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Preference"
            fetchOptions={(search: string) => retrieveCodes('CL603', search, declarant.language)}
            onChange={(selectedOption) => formik.setFieldValue('goodShipmentGlobal.preference', selectedOption?.value)}
            value={{
              value: formik.values.goodShipmentGlobal.preference,
              label: formik.values.goodShipmentGlobal.preference,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Transaction nature / delivery terms</FormSectionTitle>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Nature of transaction"
            fetchOptions={(search: string) => retrieveCodes('CL091', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('otherDataElements.natureOfTransaction', selectedOption?.value)
            }
            value={{
              value: formik.values.otherDataElements.natureOfTransaction,
              label: formik.values.otherDataElements.natureOfTransaction,
            }}
            disabled={props.editDisabled}
            onHeaderChange={(active) => formik.setFieldValue('consignment.countryOfDispatchHeader', active)}
            headerValue={formik.values.consignment.countryOfDispatchHeader}
          />
        </FormRow>
        <FormRow>
          <Autocomplete
            width={33}
            widthUnit="%"
            placeholder="Delivery terms"
            fetchOptions={(search: string) => retrieveCodes('CL090', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('valuationInformationTaxes.deliveryTerms.incotermCode', selectedOption?.value)
            }
            value={{
              value: formik.values.valuationInformationTaxes.deliveryTerms.incotermCode,
              label: formik.values.valuationInformationTaxes.deliveryTerms.incotermCode,
            }}
            disabled={props.editDisabled}
          />
          <Input
            name="valuationInformationTaxes.deliveryTerms.location"
            placeholder="Delivery Terms Place"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.valuationInformationTaxes.deliveryTerms.location}
            width={33}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Autocomplete
            width={33}
            widthUnit="%"
            placeholder="Delivery terms country"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('valuationInformationTaxes.deliveryTerms.country', selectedOption?.value)
            }
            value={{
              value: formik.values.valuationInformationTaxes.deliveryTerms.country,
              label: formik.values.valuationInformationTaxes.deliveryTerms.country,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Customs</FormSectionTitle>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Validation office"
            fetchOptions={(search: string) => retrieveCodes('CL141', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('consignment.customsOfficePresentation.referenceNumber', selectedOption?.value)
            }
            value={{
              value: formik.values.consignment.customsOfficePresentation.referenceNumber,
              label: formik.values.consignment.customsOfficePresentation.referenceNumber,
            }}
          />
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Goods location"
            onChange={(selectedOption) => {
              formik.setFieldValue('consignment.locationOfGoods.unLocode', selectedOption?.value);
            }}
            options={(goodsLocations || []).map((location) => ({
              value: location.unLocode,
              label: location.unLocode,
            }))}
            value={{
              value: formik.values.consignment.locationOfGoods.unLocode,
              label: formik.values.consignment.locationOfGoods.unLocode,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
        {isExport && (
          <FormRow>
            <Input
              name="consignment.customsOfficeExit.referenceNumber"
              placeholder="Exit office"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.consignment.customsOfficeExit.referenceNumber}
              width={50}
              widthUnit="%"
              disabled={props.editDisabled}
            />
          </FormRow>
        )}
        {!isExport && (
          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Supervising customs office"
              fetchOptions={(search: string) => retrieveCodes('CL141', search, declarant.language)}
              onChange={(selectedOption) =>
                formik.setFieldValue('consignment.customsSupervisingOffice.referenceNumber', selectedOption?.value)
              }
              value={{
                value: formik.values.consignment.customsSupervisingOffice.referenceNumber,
                label: formik.values.consignment.customsSupervisingOffice.referenceNumber,
              }}
            />
          </FormRow>
        )}
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Transport</FormSectionTitle>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Country of Dispatch"
            fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) => formik.setFieldValue('consignment.countryOfDispatch', selectedOption?.value)}
            value={{
              value: formik.values.consignment.countryOfDispatch,
              label: formik.values.consignment.countryOfDispatch,
            }}
            disabled={props.editDisabled}
            onHeaderChange={(active) => formik.setFieldValue('consignment.countryOfDispatchHeader', active)}
            headerValue={formik.values.consignment.countryOfDispatchHeader}
          />
          {isExport && (
            <Input
              name="transportInformation.departureTransportMeans.identificationNumber"
              placeholder="Departure transport means identification number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.transportInformation.departureTransportMeans.identificationNumber}
              width={50}
              widthUnit="%"
              disabled={props.editDisabled}
            />
          )}
        </FormRow>

        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Border nationality"
            fetchOptions={(search: string) => retrieveCodes('CL165', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('transportInformation.activeBorderTransportMeans.nationality', selectedOption?.value)
            }
            value={{
              value: formik.values.transportInformation.activeBorderTransportMeans.nationality,
              label: formik.values.transportInformation.activeBorderTransportMeans.nationality,
            }}
            disabled={props.editDisabled}
          />
          {isExport && (
            <Input
              name="transportInformation.activeBorderTransportMeans.identificationNumber"
              placeholder="Border identity"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.transportInformation.activeBorderTransportMeans.identificationNumber}
              width={50}
              widthUnit="%"
              disabled={props.editDisabled}
            />
          )}
        </FormRow>

        {!isExport && (
          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Country of Destination"
              fetchOptions={(search: string) => retrieveCodes('CL010', search, declarant.language)}
              onChange={(selectedOption) =>
                formik.setFieldValue('consignment.countryOfDestination', selectedOption?.value)
              }
              value={{
                value: formik.values.consignment.countryOfDestination,
                label: formik.values.consignment.countryOfDestination,
              }}
              disabled={props.editDisabled}
              onHeaderChange={(active) => formik.setFieldValue('consignment.destinationHeader', active)}
              headerValue={formik.values.consignment.destinationHeader}
            />
            <InputHeader
              name="consignment.regionOfDestination"
              placeholder="Region of Destination"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.consignment.regionOfDestination}
              width={50}
              widthUnit="%"
              disabled={props.editDisabled}
              onHeaderChange={(active) => formik.setFieldValue('consignment.destinationHeader', active)}
              headerValue={formik.values.consignment.destinationHeader}
            />
          </FormRow>
        )}
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Inland transport mode"
            fetchOptions={(search: string) => retrieveCodes('CL018', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('transportInformation.inlandModeOfTransport', selectedOption?.value)
            }
            value={{
              value: formik.values.transportInformation.inlandModeOfTransport?.toString(),
              label: formik.values.transportInformation.inlandModeOfTransport?.toString(),
            }}
            disabled={props.editDisabled}
          />
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Transport mode at Border"
            fetchOptions={(search: string) => retrieveCodes('CL018', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('transportInformation.modeOfTransportMeansAtTheBorder', selectedOption?.value)
            }
            value={{
              value: formik.values.transportInformation.modeOfTransportMeansAtTheBorder,
              label: formik.values.transportInformation.modeOfTransportMeansAtTheBorder,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
        {!isExport && (
          <FormRow>
            <Input
              name="transportInformation.arrivalTransportMeans.identificationNumber"
              placeholder="Arrival transport means identification number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.transportInformation.arrivalTransportMeans.identificationNumber}
              width={50}
              widthUnit="%"
              disabled={props.editDisabled}
            />
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Arrival Transport means Type of Identification"
              fetchOptions={(search: string) => retrieveCodes('CL750', search, declarant.language)}
              onChange={(selectedOption) =>
                formik.setFieldValue(
                  'transportInformation.arrivalTransportMeans.typeOfIdentification',
                  Number(selectedOption?.value) || undefined,
                )
              }
              value={{
                value: formik.values.transportInformation.arrivalTransportMeans.typeOfIdentification?.toString(),
                label: formik.values.transportInformation.arrivalTransportMeans.typeOfIdentification?.toString(),
              }}
              disabled={props.editDisabled}
            />
          </FormRow>
        )}
        <FormRow>
          <MultipleContainers
            width={50}
            widthUnit="%"
            containers={formik.values.transportInformation.transportEquipment.containers.map(
              (c) => c.identificationNumber,
            )}
            onChange={(newList) =>
              formik.setFieldValue(
                'transportInformation.transportEquipment.containers',
                newList.map((c) => ({ identificationNumber: c })),
              )
            }
            onHeaderChange={(active) => formik.setFieldValue('transportInformation.transportEquipment.header', active)}
            headerValue={formik.values.transportInformation.transportEquipment.header}
            label="Container"
            editDisabled={props.editDisabled}
          />
          {isExport && (
            <MultipleContainers
              width={50}
              widthUnit="%"
              containers={formik.values.transportInformation.transportEquipment.seals.map(
                (s) => s.identificationNumber,
              )}
              onChange={(newList) =>
                formik.setFieldValue(
                  'transportInformation.transportEquipment.seals',
                  newList.map((s) => ({ identificationNumber: s })),
                )
              }
              editDisabled={props.editDisabled}
              label="Seal"
            />
          )}
        </FormRow>
        {isExport && (
          <FormRow>
            <MultipleContainers
              width={50}
              widthUnit={'%'}
              containers={formik.values.transportInformation.countryOfRoutingOfConsignment.map((c) => c.country)}
              onChange={(newList) =>
                formik.setFieldValue(
                  'transportInformation.countryOfRoutingOfConsignment',
                  newList.map((c) => ({ country: c })),
                )
              }
              editDisabled={props.editDisabled}
              label="Routing of Consignment country"
            />
          </FormRow>
        )}
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Packaging</FormSectionTitle>
        <FormRow>
          <Autocomplete
            width={50}
            widthUnit="%"
            placeholder="Package Type"
            fetchOptions={(search: string) => retrieveCodes('CL017', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('declarationGoods.packaging.typeOfPackages', selectedOption?.value)
            }
            value={{
              value: formik.values.declarationGoods.packaging.typeOfPackages,
              label: formik.values.declarationGoods.packaging.typeOfPackages,
            }}
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      {!props.editDisabled && (
        <div>
          <Button type="submit" primary key="save-modal-btn">
            Save
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

export default DeclarationDetailsGeneralInfo;
