import { prop, Ref } from '@typegoose/typegoose';

import { ImporterStatus } from '../../enums';
import { CompanyAddress } from './company-address';
import { Proof } from './proof';
import { IdCard } from './id-card';

import { BusinessLicense, Eori, VAT } from './business-license';
import { BaseModel } from '../../models/base.model';
import { Declarant } from '../declarant';
import { DomainCollection } from '../domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Importer extends BaseModel {
  @prop()
  name?: string;

  @prop()
  idCard: IdCard;

  @prop()
  businessLicense: BusinessLicense;

  @prop()
  eori: Eori;

  @prop()
  vat: VAT;

  @prop({ required: true, enum: ImporterStatus, type: String })
  status: ImporterStatus;

  @prop()
  url?: string;

  @prop()
  email?: string;

  @prop()
  phoneNumber?: string;

  @prop()
  logo?: string;

  @prop()
  address?: CompanyAddress;

  @prop()
  proofs?: Proof[];

  @prop({ ref: Declarant })
  declarant: Ref<Declarant>;
}
