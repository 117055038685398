import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import { batchesBoardSlice } from './batchesBoardSlice';
import batchesSlice from './batchesSlice';
import chartsReducer from './chartsSlice';
import customersReducer from './customersSlice';
import customsErrorsSlice from './customsErrorsSlice';
import declarantReducer from './declarantSlice';
import declarationGroupsReducer from './declarationGroupsSlice';
import declarationsReducer from './declarationsSlice';
import { importersSlice } from './importerSlice';
import settingsReducer from './settingsSlice';
import templatesReducer from './templatesSlice';
import userReducer from './userSlice';

export const store = configureStore({
  reducer: {
    customsErrors: customsErrorsSlice,
    batchesBoard: batchesBoardSlice.reducer,
    batches: batchesSlice,
    customers: customersReducer,
    templates: templatesReducer,
    declarant: declarantReducer,
    declarationGroups: declarationGroupsReducer,
    declarations: declarationsReducer,
    settings: settingsReducer,
    user: userReducer,
    charts: chartsReducer,
    importersSlice: importersSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
