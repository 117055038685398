import { Declaration } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { selectDeclarant } from '../../stores/declarantSlice';
import { retrieveCodes } from '../../stores/settingsSlice';
import { Input, Autocomplete } from '..';
import { FormSection, FormSectionTitle, FormRow } from '../../styles/common';

interface IDeclarantFormProps {
  declarationDetails: Declaration;
  isHighValueNL: boolean;
  formik: FormikProps<any>;
  editDisabled: boolean;
}

// eslint-disable-next-line complexity
export const DeclarantForm: React.FC<IDeclarantFormProps> = ({
  declarationDetails,
  isHighValueNL,
  formik,
  editDisabled,
}) => {
  const declarant = useSelector(selectDeclarant);

  return (
    <FormSection verticalPadding={20} topPadding={0} paddingBottom={0}>
      <FormSectionTitle spaceBetween>Declarant</FormSectionTitle>
      <FormRow>
        <Input
          name="stakeholders.declarant.name"
          placeholder="Name"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={
            isHighValueNL
              ? formik.values.stakeholders.declarant.name
              : declarationDetails?.customsReport?.declarant?.name || declarationDetails?.customer?.declarant?.name
          }
          width={50}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
        <Input
          name="stakeholders.declarant.identificationNumber"
          placeholder="Identification number"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={
            isHighValueNL
              ? formik.values.stakeholders.declarant.identificationNumber
              : declarationDetails?.customsReport?.declarant?.identificationNumber ||
                declarationDetails?.customer?.declarant?.identificationNumber
          }
          width={50}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
      </FormRow>
      <FormRow>
        <Input
          name="stakeholders.declarant.address.street"
          placeholder="Street and number"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={
            isHighValueNL
              ? formik.values.stakeholders.declarant.address.street
              : declarationDetails?.customsReport?.declarant?.address?.street ||
                declarationDetails?.customer?.declarant?.address?.street
          }
          width={40}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
        <Input
          name="stakeholders.declarant.address.postcode"
          placeholder="Postcode"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={
            isHighValueNL
              ? formik.values.stakeholders.declarant.address.postcode
              : declarationDetails?.customsReport?.declarant?.address?.postcode ||
                declarationDetails?.customer?.declarant?.address?.postcode
          }
          width={30}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
        <Input
          name="stakeholders.declarant.address.city"
          placeholder="City"
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          value={
            isHighValueNL
              ? formik.values.stakeholders.declarant.address.city
              : declarationDetails?.customsReport?.declarant?.address?.city ||
                declarationDetails?.customer?.declarant?.address?.city
          }
          width={30}
          widthUnit="%"
          disabled={!isHighValueNL || editDisabled}
          defaultBehaviour
        />
      </FormRow>
      <FormRow>
        {isHighValueNL ? (
          <Autocomplete
            width={30}
            widthUnit="%"
            placeholder="Country"
            fetchOptions={(search) => retrieveCodes('CL199', search, declarant.language)}
            onChange={(selectedOption) =>
              formik.setFieldValue('stakeholders.declarant.address.country', selectedOption?.value)
            }
            value={{
              value: formik.values.stakeholders.declarant.address.country,
              label: formik.values.stakeholders.declarant.address.country,
            }}
            disabled={editDisabled}
          />
        ) : (
          <Input
            disabled
            placeholder="Country"
            value={
              declarationDetails?.customsReport?.declarant?.address?.country ||
              declarationDetails?.customer?.declarant?.address?.country
            }
          />
        )}
      </FormRow>
      <FormSection>
        <FormSectionTitle spaceBetween>Declarant Contact Person</FormSectionTitle>
        <FormRow>
          <Input
            name="stakeholders.declarant.contactPerson.name"
            placeholder="Name"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={
              isHighValueNL
                ? formik.values.stakeholders.declarant.contactPerson.name
                : declarationDetails?.customsReport?.declarant?.contactPerson?.name ||
                  declarationDetails?.customer?.declarant?.contactPerson?.name
            }
            width={33}
            widthUnit="%"
            disabled={!isHighValueNL || editDisabled}
          />
          <Input
            name="stakeholders.declarant.contactPerson.phoneNumber"
            placeholder="Phone number"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={
              isHighValueNL
                ? formik.values.stakeholders.declarant.contactPerson.phoneNumber
                : declarationDetails?.customsReport?.declarant?.contactPerson?.phoneNumber ||
                  declarationDetails?.customer?.declarant?.contactPerson?.phoneNumber
            }
            width={33}
            widthUnit="%"
            disabled={!isHighValueNL || editDisabled}
          />
          <Input
            name="declarant.contactPerson.email"
            placeholder="Email"
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            value={
              isHighValueNL
                ? formik.values.stakeholders.declarant.contactPerson.email
                : declarationDetails?.customsReport?.declarant?.contactPerson?.email ||
                  declarationDetails?.customer?.declarant?.contactPerson?.email
            }
            width={33}
            widthUnit="%"
            disabled={!isHighValueNL || editDisabled}
          />
        </FormRow>
      </FormSection>
    </FormSection>
  );
};

export default DeclarantForm;
