// eslint-disable-next-line no-use-before-define, no-restricted-imports
// eslint-disable-next-line no-use-before-define
import React, { useMemo } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import * as Style from '../importer-details.style';
import {
  TableActionButtons,
  TableActions,
} from '../../../components/table-action-buttons/table-action-buttons.control';

import { RelatedResourceStatusIcon } from '../../../components/related-resource-status-icon/related-resource-status-icon.component';

export interface IIdentificationDocumentItem {
  importer: any;
}

export const ImporterIdentification: React.FC<IIdentificationDocumentItem> = ({ importer }) => {
  const importerDocumentsList = useMemo(
    () =>
      [
        { document: 'ID Card', type: 'ID_CARD', relatedResource: importer?.idCard.relatedResources[0] },
        {
          document: 'Chinese business license',
          type: 'BUSINESS_LICENSE',
          relatedResource: importer?.businessLicense.relatedResources[0],
        },
        { document: 'EORI certificate', type: 'EORI', relatedResource: importer?.eori.relatedResources[0] },
        { document: 'VAT certificate', type: 'VAT', relatedResource: importer?.vat.relatedResources[0] },
        {
          document: 'Tax return paperwork',
          type: 'TAX',
          relatedResource: importer?.taxReturn?.relatedResources[0],
        },
      ].map((doc) => ({
        document: doc.document,
        type: doc.type,
        filename: doc.relatedResource?.fileName || '',
        format: doc.relatedResource?.mediaType || '',
        status: doc.relatedResource?.status || 'EMPTY',
      })),
    [importer],
  );

  console.log(importerDocumentsList);
  console.log(importer);

  const columns: IDataTableColumn[] = [
    {
      name: 'Identification document',
      cell: (row) => <div style={{ textAlign: 'start' }}>{row.document}</div>,
      sortable: false,
    },
    {
      name: 'File name',
      cell: (row) => <div style={{ textAlign: 'start' }}>{row.filename}</div>,
    },
    {
      name: 'Format',
      cell: (row) => <div style={{ textAlign: 'start' }}>{row.format.toUpperCase()}</div>,
    },
    {
      name: 'Status',
      selector: (row) => <RelatedResourceStatusIcon status={row.status} />,
    },
    {
      name: 'Actions',
      right: true,
      cell: () => (
        <TableActionButtons
          actions={[TableActions.Edit]}
          onClick={() => {
            console.log('');
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <Style.DocumentsContainer>
        <DataTable noHeader columns={columns} data={importerDocumentsList} pagination />
      </Style.DocumentsContainer>
    </div>
  );
};
