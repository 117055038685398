import { RelatedResourceStatus } from '@e-origin/shared';
import { StatusIcons } from '../../assets';

import * as Style from './related-resource-status-icon.style';

interface RelatedResourceStatusProps {
  status: RelatedResourceStatus;
}

export const RelatedResourceStatusIcon: React.FC<RelatedResourceStatusProps> = ({ status }) => {
  const statusIconMapper = new Map([
    [
      RelatedResourceStatus.NEW,
      <Style.StatusWrapper key={0}>
        <img src={StatusIcons.NOT_SENT} />
        <span>No document provided</span>
      </Style.StatusWrapper>,
    ],
    [
      RelatedResourceStatus.EMPTY,
      <Style.StatusWrapper key={0}>
        <img src={StatusIcons.NOT_SENT} />
        <span>No document provided</span>
      </Style.StatusWrapper>,
    ],
    [
      RelatedResourceStatus.NOK,
      <Style.StatusWrapper key={1}>
        <img src={StatusIcons.YELLOW_WARNING} />
        <span>Invalid document</span>
      </Style.StatusWrapper>,
    ],
    [
      RelatedResourceStatus.OK,
      <Style.StatusWrapper key={2}>
        <img src={StatusIcons.RELEASED} />
        <span>Check OCR ok</span>
      </Style.StatusWrapper>,
    ],
  ]);

  return statusIconMapper.get(status);
};
