/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useSelector } from 'react-redux';

/* eslint-disable no-empty-function */
import { GoodLocation } from '@e-origin/shared';

import viewIcon from '../../../assets/icons/view-icon.png';
import { Button, SearchBox } from '../../../components';
import { selectDeclarantGoodsLocations } from '../../../stores/declarantSlice';
import { DataTableControls, PageContainer, PageHeader, TableActionButton } from '../../../styles/common';
import GoodLocationModal from './good-location-modal/good-location-modal.component';
import { Container, DataTableContainer } from './goods-location.style';

const GoodsLocation = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GoodLocation | null>(null);
  const goodsLocations = useSelector(selectDeclarantGoodsLocations);

  const toggleShowGoodsModal = () => {
    setShowModal(!showModal);
    if (!showModal) {
      setSelectedRow(null);
    }
  };

  const locationTypes = [
    { value: 'A', label: 'A - Designated location' },
    { value: 'B', label: 'B - Authorised place' },
    { value: 'C', label: 'C - Approved place' },
    { value: 'D', label: 'D - Other' },
  ];

  const qualifierTypes = [
    { value: 'T', label: 'T - Postcode address' },
    { value: 'U', label: 'U - UN/LOCODE' },
    { value: 'V', label: 'V - Customs office identifier' },
    { value: 'W', label: 'W - GNSS coordinates' },
    { value: 'X', label: 'X - EORI number' },
    { value: 'Y', label: 'Y - Authorisation number' },
    { value: 'Z', label: 'Z - Address' },
  ];

  const columns: IDataTableColumn<GoodLocation>[] = [
    {
      name: 'Unlocode',
      selector: 'unLocode',
      sortable: false,
    },
    {
      name: 'Type',
      selector: 'type',
      sortable: false,
      cell: (row) => locationTypes.find((item) => item.value === row.type)?.label || row.type,
    },
    {
      name: 'Qualifier',
      selector: 'qualifierOfIdentification',
      sortable: false,
      cell: (row) => qualifierTypes.find((item) => item.value === row.qualifierOfIdentification)?.label || row.type,
    },
    {
      name: 'Postcode',
      selector: 'displayedPostcode',
      sortable: false,
    },
    {
      name: 'City',
      selector: 'displayedCity',
      sortable: false,
    },
    {
      name: 'Default Location',
      selector: 'defaultLocation',
      sortable: false,
      cell: (row) => (row.defaultLocation ? 'True' : 'False'),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div>
          <TableActionButton
            onClick={() => {
              setSelectedRow(row);
              setShowModal(true);
            }}
          >
            <img src={viewIcon} alt="" />
          </TableActionButton>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <PageContainer>
        <PageHeader noTitle>
          <DataTableControls>
            <Button outline onClick={toggleShowGoodsModal}>
              Add new
            </Button>
            <SearchBox handleSearch={() => {}} />
          </DataTableControls>
        </PageHeader>
        <DataTableContainer>
          <DataTable noHeader columns={columns} data={goodsLocations || []} pagination />
        </DataTableContainer>
      </PageContainer>
      {showModal && (
        <GoodLocationModal show={showModal} onHide={toggleShowGoodsModal} goodLocationToEdit={selectedRow} />
      )}
    </Container>
  );
};

export default GoodsLocation;
