import styled from 'styled-components';

export const FlexWrapContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 16px;
  height: 100%;

  & > * {
    width: 33%;
  }
`;
