// eslint-disable-next-line no-restricted-imports
import { RelatedResourceStatus } from '@e-origin/shared/src/models/customs-domain/related-resource';
// eslint-disable-next-line no-use-before-define
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import * as Style from '../importer-details.style';
import {
  TableActionButtons,
  TableActions,
} from '../../../components/table-action-buttons/table-action-buttons.control';

export interface IPOAItem {
  documents?: {
    document: string;
    filename: string;
    date: string;
    status: string | RelatedResourceStatus;
  }[];
}

export const ImporterPOA: React.FC<IPOAItem> = ({ documents }) => {
  const mockDocuments = [
    {
      document: 'POA',
      filename: 'POA original document',
      date: '30-11-21 @ 09:12',

      status: RelatedResourceStatus.SENT,
    },
    {
      document: '',
      filename: 'POA my signature',
      date: '30-11-21 @ 09:12',
      status: RelatedResourceStatus.SENT,
    },
    {
      document: '',
      filename: 'POA signed by Custom Broker',
      date: '30-11-21 @ 09:12',
      status: RelatedResourceStatus.SENT,
    },
    {
      document: 'VAT GUARANTEE',
      filename: 'VAT original document',
      date: '30-11-21 @ 09:12',
      status: RelatedResourceStatus.NOT_SENT,
    },
    {
      document: '',
      filename: 'VAT my signature',
      date: '30-11-21 @ 09:12',
      status: RelatedResourceStatus.IN_PROGRESS,
    },
    {
      document: '',
      filename: 'VAT signed by Custom Broker',
      date: '30-11-21 @ 09:12',
      status: RelatedResourceStatus.IN_PROGRESS,
    },

    {
      document: '',
      filename: 'Missing resource example',
      date: '30-11-21 @ 09:12',
      status: 'EMPTY',
    },
  ];

  const columns: IDataTableColumn[] = [
    {
      name: 'Identification document',
      selector: 'document',
      width: '250px',
      cell: (row) => <span style={{ color: '#1870A3', fontWeight: '700' }}>{row.document}</span>,
    },
    {
      name: 'File name',
      selector: 'filename',
      width: '270px',
    },
    {
      name: 'Date',
      selector: 'date',
      width: '200px',
    },
    {
      name: 'Status',
      selector: 'status',
      width: '290px',
    },
    {
      name: 'Actions',
      right: true,
      cell: () => (
        <div style={{ display: 'flex', gap: '15px' }}>
          <div
            style={{
              width: '133px',
              height: '27px',
              background: '#1770A3',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button style={{ color: 'white', fontSize: '11px', fontWeight: '700' }}>Send my signed file</button>
          </div>
          <TableActionButtons
            actions={[TableActions.Edit]}
            onClick={() => {
              console.log('');
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <Style.DocumentsContainer>
        <DataTable noHeader columns={columns} data={documents || mockDocuments} pagination />
      </Style.DocumentsContainer>
    </div>
  );
};
