import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { Header, Navigation } from '../../components';
import { useConfig } from '../../hooks/use-config';
import Batches from '../../pages/batches/batches.component';
import CustomerDetails from '../../pages/customer-details/customer-details.component';
import Customers from '../../pages/customers/customers.component';
import DeclarationDetails from '../../pages/declaration-details/declaration-details.component';
import DeclarationGroups from '../../pages/declaration-groups/declaration-groups.component';
import Importers from '../../pages/importers/importers.component';
import Declarations from '../../pages/declarations/declarations.component';
import Help from '../../pages/help/help.component';
import ManageAccount from '../../pages/manage-account/manage-account.component';
import { MongoDashboard } from '../../pages/mongo-dashboard/mongo-dashboard';
import ManageTemplates from '../../pages/templates/manage-templates.component';
import { getUserInfo, refreshToken, selectUserRole, signOutUser } from '../../stores/userSlice';
import { Body, Container, NavigationContainer, PageContainer, PageWrapper } from './layout.style';
import { selectSettings } from '../../../src/stores/settingsSlice';
import ImportersDetails from '../../pages/importer-details/importer-details.component';

const Layout = () => {
  const { walletEnabled } = useSelector(selectSettings);
  const dispatch = useDispatch();
  const history = useHistory();
  const { config } = useConfig();

  const roleName = useSelector(selectUserRole);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    dispatch(refreshToken());
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (config) {
      const navigationCallback = () => {
        history.push('/');
      };

      const timeout = setTimeout(() => {
        dispatch(signOutUser(navigationCallback));
      }, 1000 * config.JWT_EXPIRATION_TIME);

      return () => clearTimeout(timeout);
    }
  }, [config]);

  return (
    <Container>
      <Header />
      <Body>
        <NavigationContainer>
          <Navigation />
        </NavigationContainer>
        <PageContainer>
          <PageWrapper>
            <Switch>
              <Route path="/batches">
                <Batches />
              </Route>
              <Route path="/declarations/:declarationId">
                <DeclarationDetails />
              </Route>
              <Route path="/declarations">
                <Declarations />
              </Route>
              <Route path={['/declaration-groups']}>
                <DeclarationGroups />
              </Route>

              {/* {walletEnabled && ( */}
              <Route path="/importers/:id">
                <ImportersDetails />
              </Route>
              {/* )} */}

              {/* {walletEnabled && ( */}
              <Route path="/importers">
                <Importers />
              </Route>
              {/* )} */}

              <Route path="/customers/:customerId/batches">
                <Batches />
              </Route>
              <Route path="/customers/:customerId/declarations">
                <Declarations />
              </Route>
              <Route path="/customers/:customerId">
                <CustomerDetails />
              </Route>
              <Route path="/customers/add">
                <CustomerDetails />
              </Route>
              <Route path="/customers">
                <Customers />
              </Route>
              <Route path="/manage-account">
                <ManageAccount />
              </Route>
              {roleName === 'admin' && (
                <Route path="/manage-templates">
                  <ManageTemplates />
                </Route>
              )}
              <Route path="/mongo-dashboard">
                <MongoDashboard />
              </Route>
              <Route path="/help">
                <Help />
              </Route>

              <Route>
                <Redirect to="/batches" />
              </Route>
            </Switch>
          </PageWrapper>
        </PageContainer>
      </Body>
    </Container>
  );
};

export default Layout;
