import { index, prop } from '@typegoose/typegoose';

import { ConnectionTypes } from '../connection.types';
import { DomainCollection } from '../domain.collection';

@index({ code: 1 })
@DomainCollection({ schemaOptions: { timestamps: true }, connectionName: ConnectionTypes.Catalogs })
export class Duty {
  @prop()
  code: string;

  @prop()
  addCode: string;

  @prop()
  orderNr: string;

  @prop()
  startDate: Date;

  @prop()
  endDate: Date;

  @prop()
  redInd: string;

  @prop()
  origin: string;

  @prop()
  measureType: string;

  @prop()
  originCode: string;

  @prop()
  legalBase: string;

  @prop()
  duty: string;

  @prop()
  typeCode: string;
}
