/* eslint-disable no-use-before-define */
/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';

/* eslint-disable max-statements */
import { Batch, BatchStatusEnum } from '@e-origin/shared';

import { StatusIcons } from '../../../../assets';
import { SendingIndicator, Spinner, SyncingIndicator } from '../../../../components';
import { Container, SvgIcon } from '../batch-status-indicator.style';

interface IBatchStatusIndicator {
  batch: Batch;
}

const NO_STATISTICS_STATUSES = ['failed', 'in_progress', 'in_validation', 'scraping', 'sending', 'syncing'];

const BatchLVStatusIndicator: React.FC<IBatchStatusIndicator> = (props) => {
  const { batch } = props;

  const hasCustomerProcessingIcon = batch.status.value === BatchStatusEnum.IN_VALIDATION;

  const hasRejectedIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics ? batch.statistics.rejected > 0 : false;

  const hasNotReleasedIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? batch.statistics?.notReleased > 0
      : false;

  const initialHasNotSentIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? batch.statistics?.sent < batch.statistics?.total
      : false;

  const hasReleasedIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? batch.statistics?.released === batch.statistics?.total
      : false;

  const hasCustomsProcessingIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? ((batch.statistics.sent === batch.statistics.total && batch.statistics.released < batch.statistics.sent) ||
          (batch.statistics.beGateSent === batch.statistics.total &&
            batch.statistics.beGateReleased < batch.statistics.beGateSent &&
            batch.statistics.beGateNotReleased < batch.statistics.beGateSent)) &&
        !batch.statistics.notReleased &&
        !batch.statistics.rejected
      : false;

  const hasSendingIcon =
    batch.status.value === BatchStatusEnum.SENDING &&
    batch.statistics &&
    batch.statistics?.sent < batch.statistics?.total;

  const anomaliesDetected =
    batch.statistics?.analysisSplitShipment > 0 ||
    batch.statistics?.analysisIOSS > 0 ||
    batch.statistics?.analysisUndervaluation > 0;

  const hasSyncingIcon = batch.status.value === 'syncing';

  const [hasFailedIcon, setHasFailedIcon] = useState(batch.status.value === 'failed');
  const [hasNotSentIcon, setHasNotSentIcon] = useState(initialHasNotSentIcon);
  const [hasProcessingIcon, setHasProcessingIcon] = useState(batch.status.value === 'in_progress');

  const previousBatchStatus = useRef<string>();

  useEffect(() => {
    if (batch.status.value !== previousBatchStatus.current) {
      if (hasProcessingIcon && ['success', 'failed'].includes(batch.status.value)) {
        setHasProcessingIcon(false);

        if (batch.status.value === 'failed') {
          setHasFailedIcon(true);
        } else {
          setHasNotSentIcon(true);
        }
      }
      previousBatchStatus.current = batch.status.value;
    }
  }, [batch.status.value]);

  if (hasFailedIcon) {
    return (
      <Container>
        <SvgIcon>
          <img src={StatusIcons.FAILED} alt={'failed'} />
        </SvgIcon>
      </Container>
    );
  }

  if (hasSendingIcon) {
    return (
      <Container>
        <SendingIndicator />
      </Container>
    );
  }

  if (hasCustomsProcessingIcon) {
    return (
      <Container>
        <SvgIcon>
          <img src={StatusIcons.CUSTOMS_PROCESSING} alt={'customs-processing'} />
        </SvgIcon>
      </Container>
    );
  }

  // show splitShipment status icons only if analysisSplitShipment is defined (i.e. older declarations
  // will not be takend into account) and if there are declarations in NOT_SENT status
  if (
    batch.statistics.sent !== batch.statistics.total &&
    (batch.statistics.analysisSplitShipment !== undefined ||
      batch.statistics.analysisIOSS !== undefined ||
      batch.statistics.analysisUndervaluation !== undefined)
  ) {
    return (
      <Container>
        {!anomaliesDetected ? (
          <SvgIcon>
            <img src={StatusIcons.GREEN_CIRCLE} />
          </SvgIcon>
        ) : (
          <SvgIcon>
            <img src={StatusIcons.YELLOW_WARNING} />
          </SvgIcon>
        )}
      </Container>
    );
  }

  return (
    <Container>
      {hasNotSentIcon && (
        <SvgIcon>
          <img src={StatusIcons.NOT_SENT} alt={'not-sent'} />
        </SvgIcon>
      )}
      {hasReleasedIcon && (
        <SvgIcon>
          <img src={StatusIcons.RELEASED} alt={'released'} />
        </SvgIcon>
      )}
      {hasRejectedIcon && (
        <SvgIcon>
          <img src={StatusIcons.REJECTED} alt={'rejected'} />
        </SvgIcon>
      )}
      {hasNotReleasedIcon && (
        <SvgIcon>
          <img src={StatusIcons.NOT_RELEASED} alt={'not-released'} />
        </SvgIcon>
      )}
      {hasSyncingIcon && <SyncingIndicator />}
      {hasProcessingIcon && <Spinner />}
      {hasCustomerProcessingIcon && (
        <SvgIcon>
          <img src={StatusIcons.CUSTOMER_PROCESSING} alt={'customer-processing'} />
        </SvgIcon>
      )}
      {!hasFailedIcon &&
        !hasCustomerProcessingIcon &&
        !hasNotSentIcon &&
        !hasReleasedIcon &&
        !hasRejectedIcon &&
        !hasNotReleasedIcon &&
        !hasCustomsProcessingIcon &&
        !hasSendingIcon &&
        !hasSyncingIcon &&
        !hasProcessingIcon && (
          <SvgIcon>
            <img src={StatusIcons.CUSTOMS_PROCESSING} alt={'customs-processing'} />
          </SvgIcon>
        )}
    </Container>
  );
};

export default BatchLVStatusIndicator;
