import { FormikConfig, useFormik } from 'formik';
import numeral from 'numeral';
/* eslint-disable complexity */
import { FC } from 'react';
import isAlpha from 'validator/lib/isAlpha';

import { container, TYPES } from '@e-origin/ioc';
/* eslint-disable max-lines */
import { Declaration, DeclarationGoods, DutiesAndTaxesItem } from '@e-origin/shared';

import { Autocomplete, Button, Input, Modal } from '../../../../components';
import { DeclarationService } from '../../../../services';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../styles/common';
import { retrieveCodes } from '../../../../stores/settingsSlice';
import { useSelector } from 'react-redux';
import { selectDeclarant } from '../../../../stores/declarantSlice';

interface IDeclarationDetailsGoodsModalLowValuesProps {
  editDisabled?: boolean;
  declaration: Declaration;
  goodDetails: DeclarationGoods;
  onHide(): void;
}

interface IDeclarationDetailsLowValuesFormikValues {
  _id: DeclarationGoods['_id'];
  referenceNumberUCR: DeclarationGoods['referenceNumberUCR'];
  additionalProcedure: string;
  descriptionOfGoods: DeclarationGoods['descriptionOfGoods'];
  commodityCode: {
    hsCode: DeclarationGoods['commodityCode']['hsCode'];
  };
  goodsMeasure: {
    intrinsicValue: DeclarationGoods['goodsMeasure']['intrinsicValue'];
    currencyCode: DeclarationGoods['goodsMeasure']['currencyCode'];
    grossMass: DeclarationGoods['goodsMeasure']['grossMass'];
    supplementaryUnits: DeclarationGoods['goodsMeasure']['supplementaryUnits'];
  };
  transportInsurance: {
    currencyCode: DeclarationGoods['transportInsurance']['currencyCode'];
    amount: DeclarationGoods['transportInsurance']['amount'];
  };
  packaging: {
    numberOfPackages: DeclarationGoods['packaging']['numberOfPackages'];
  };
  dutiesAndTaxes: {
    methodOfPayment: DutiesAndTaxesItem['methodOfPayment'];
  };
}

const ADDITIONAL_PROCEDURE = 'Additional procedure';
const AMOUNT = 'Amount';
const COMMODITY_CODE = 'Commodity code';
const CURRENCY_CODE = 'Currency code';
const DESCRIPTION_OF_GOODS = 'Description of goods';
const DUTIES_AND_TAXES = 'Duties & Taxes';
const GOODS_MEASURE = 'Goods measure';
const GROSS_MASS = 'Gross mass';
const HS_CODE = 'HS Code';
const INTRINSIC_VALUE = 'Intrinsic value';
const METHOD_OF_PAYMENT = 'Method of payment';
const NUMBER_OF_PACKAGES = 'Number of packages';
const ONLY_LETTERS_ALLOWED = 'Only letters allowed';
const PACKAGING = 'Packaging';
const REFERENCE_NUMBER_UCR = 'Reference number UCR';
const SAVE_BUTTON = 'Save';
const SUPPLEMENTARY_UNITS = 'Supplementary units';
const TRANSPORT_AND_INSURANCE_COSTS = 'Transport and insurance costs';

const DeclarationDetailsGoodsModalLowValues: FC<IDeclarationDetailsGoodsModalLowValuesProps> = ({
  declaration,
  goodDetails,
  editDisabled,
  onHide,
}) => {
  const declarationService: DeclarationService = container.get(TYPES.DeclarationService);

  const declarant = useSelector(selectDeclarant);

  const validate: FormikConfig<IDeclarationDetailsLowValuesFormikValues>['validate'] = (values) => {
    const errors: Partial<Record<keyof IDeclarationDetailsLowValuesFormikValues, any>> = {};

    if (!isAlpha(values.transportInsurance.currencyCode)) {
      errors.transportInsurance = {};
      errors.transportInsurance.currencyCode = ONLY_LETTERS_ALLOWED;
    }

    return errors;
  };

  const formik = useFormik<IDeclarationDetailsLowValuesFormikValues>({
    initialValues: {
      _id: goodDetails?._id || '',
      referenceNumberUCR: goodDetails?.referenceNumberUCR || '',
      additionalProcedure: goodDetails?.additionalProcedure.join(';') || '',
      descriptionOfGoods: goodDetails?.descriptionOfGoods || '',
      commodityCode: {
        hsCode: goodDetails?.commodityCode?.hsCode?.trim() || '',
      },
      goodsMeasure: {
        intrinsicValue: goodDetails?.goodsMeasure?.intrinsicValue
          ? Number(numeral(goodDetails?.goodsMeasure?.intrinsicValue).format('0.00'))
          : 0,
        currencyCode: goodDetails?.goodsMeasure?.currencyCode || '',
        grossMass: goodDetails?.goodsMeasure?.grossMass || 0,
        supplementaryUnits: goodDetails?.goodsMeasure?.supplementaryUnits || 0,
      },
      transportInsurance: {
        currencyCode: goodDetails?.transportInsurance?.currencyCode || 'EUR',
        amount: goodDetails?.transportInsurance?.amount
          ? Number(numeral(goodDetails?.transportInsurance?.amount).format('0.00'))
          : 0,
      },
      packaging: {
        numberOfPackages: goodDetails?.packaging?.numberOfPackages || 0,
      },
      dutiesAndTaxes: {
        methodOfPayment: goodDetails?.dutiesAndTaxes?.items?.length
          ? goodDetails?.dutiesAndTaxes.items[0].methodOfPayment
          : '',
      },
    },
    validate,
    onSubmit: async (values) => {
      const updatedGood: DeclarationGoods = { ...goodDetails };
      const { additionalProcedure, ...restFormFields } = values;
      Object.assign(updatedGood, { ...restFormFields, additionalProcedure: additionalProcedure.split(';') });

      declarationService.saveMultipleGoods({ declaration, updatedGoods: [updatedGood] });
      onHide();
    },
    enableReinitialize: false,
  });

  const { errors, handleBlur, handleChange, handleSubmit, submitForm, touched, values } = formik;

  const {
    commodityCode,
    descriptionOfGoods,
    dutiesAndTaxes,
    goodsMeasure,
    packaging,
    referenceNumberUCR,
    transportInsurance,
  } = values;

  const startFormSubmit = () => {
    submitForm();
  };

  return (
    <Modal
      title={`Good details ${goodDetails ? `- ${goodDetails.goodsItemNumber}` : ''}`}
      show={true}
      onHide={() => onHide()}
      buttons={
        editDisabled
          ? []
          : [
              <Button type="button" primary key="save-modal-btn" onClick={startFormSubmit}>
                {SAVE_BUTTON}
              </Button>,
            ]
      }
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={handleSubmit}>
        <FormSection verticalPadding={20} topPadding={40}>
          <FormRow>
            <Input
              name="referenceNumberUCR"
              placeholder={REFERENCE_NUMBER_UCR}
              onChange={handleChange}
              onBlur={handleBlur}
              value={referenceNumberUCR}
              width={50}
              widthUnit="%"
              disabled={editDisabled}
            />
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder={ADDITIONAL_PROCEDURE}
              fetchOptions={(search: string) => retrieveCodes('CL457', search, declarant.language)}
              onChange={(selectedOption) => formik.setFieldValue('additionalProcedure', selectedOption?.value)}
              value={{
                value: formik.values.additionalProcedure,
                label: formik.values.additionalProcedure,
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              name="descriptionOfGoods"
              placeholder={DESCRIPTION_OF_GOODS}
              onChange={handleChange}
              onBlur={handleBlur}
              value={descriptionOfGoods}
              width={100}
              widthUnit="%"
              disabled={editDisabled}
            />
          </FormRow>
          <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
            <FormSectionTitle>{COMMODITY_CODE}</FormSectionTitle>
            <FormRow>
              <Input
                name="commodityCode.hsCode"
                placeholder={HS_CODE}
                onChange={(event) => {
                  formik.setFieldValue('commodityCode.hsCode', event.target.value.trim());
                }}
                onBlur={handleBlur}
                value={commodityCode.hsCode}
                width={100}
                widthUnit="%"
                disabled={editDisabled}
              />
            </FormRow>
          </FormSection>
          <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
            <FormSectionTitle>{GOODS_MEASURE}</FormSectionTitle>
            <FormRow>
              <Input
                name="goodsMeasure.intrinsicValue"
                placeholder={INTRINSIC_VALUE}
                onChange={handleChange}
                onBlur={handleBlur}
                value={goodsMeasure.intrinsicValue}
                width={50}
                widthUnit="%"
                type="number"
                disabled={editDisabled}
              />
              <Input
                name="goodsMeasure.currencyCode"
                placeholder={CURRENCY_CODE}
                onChange={handleChange}
                onBlur={handleBlur}
                value={goodsMeasure.currencyCode}
                width={50}
                widthUnit="%"
                disabled={editDisabled}
              />
            </FormRow>
            <FormRow>
              <Input
                name="goodsMeasure.grossMass"
                placeholder={GROSS_MASS}
                onChange={handleChange}
                onBlur={handleBlur}
                value={goodsMeasure.grossMass}
                width={50}
                widthUnit="%"
                type="number"
                disabled={editDisabled}
              />
              <Input
                name="goodsMeasure.supplementaryUnits"
                placeholder={SUPPLEMENTARY_UNITS}
                onChange={handleChange}
                onBlur={handleBlur}
                value={goodsMeasure.supplementaryUnits}
                width={50}
                widthUnit="%"
                type="number"
                disabled={editDisabled}
              />
            </FormRow>
          </FormSection>
          <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
            <FormSectionTitle>{TRANSPORT_AND_INSURANCE_COSTS}</FormSectionTitle>
            <FormRow>
              <Input
                name="transportInsurance.currencyCode"
                placeholder={CURRENCY_CODE}
                onChange={handleChange}
                onBlur={handleBlur}
                value={transportInsurance.currencyCode}
                width={50}
                widthUnit="%"
                disabled={editDisabled}
                invalid={
                  touched.transportInsurance?.currencyCode &&
                  errors.transportInsurance?.currencyCode !== undefined &&
                  errors.transportInsurance?.currencyCode.length > 0
                }
              />
              <Input
                name="transportInsurance.amount"
                placeholder={AMOUNT}
                onChange={handleChange}
                onBlur={handleBlur}
                value={transportInsurance.amount}
                width={50}
                widthUnit="%"
                type="number"
                disabled={editDisabled}
              />
            </FormRow>
          </FormSection>

          <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
            <FormSectionTitle>{PACKAGING}</FormSectionTitle>
            <FormRow>
              <Input
                name="packaging.numberOfPackages"
                placeholder={NUMBER_OF_PACKAGES}
                onChange={handleChange}
                onBlur={handleBlur}
                value={packaging.numberOfPackages}
                width={100}
                widthUnit="%"
                type="number"
                disabled={editDisabled}
              />
            </FormRow>
          </FormSection>

          <FormSection verticalPadding={10} topPadding={0} paddingBottom={0}>
            <FormSectionTitle>{DUTIES_AND_TAXES}</FormSectionTitle>
            <FormRow>
              <Input
                name="dutiesAndTaxes.methodOfPayment"
                placeholder={METHOD_OF_PAYMENT}
                onChange={handleChange}
                onBlur={handleBlur}
                value={dutiesAndTaxes.methodOfPayment}
                width={100}
                widthUnit="%"
                disabled={editDisabled}
              />
            </FormRow>
          </FormSection>
        </FormSection>
      </FormContainer>
    </Modal>
  );
};

export default DeclarationDetailsGoodsModalLowValues;
