import { useState } from 'react';

import Icons from '../../../../assets';
import { generateBatchByLRN } from '../../../../stores/declarationsSlice';
import ModalStyle from '../../../../styles/modal';
import { Button, CustomModal, Input } from '../../..';
import * as Style from './declaration-regularization-modal.style';

interface IDeclarationRegularizationModalProps {
  LRN: string;
  referenceNumberUCR: string;
  open: boolean;
  onHide: () => void;
  onFinish: () => void;
}

export const DeclarationRegularizationModal: React.FC<IDeclarationRegularizationModalProps> = (props) => {
  const [batchName, setBatchName] = useState(`REGUL${props.referenceNumberUCR}`);

  const handleRegularization = () => {
    generateBatchByLRN(props.LRN, batchName, true).then(() => {
      props.onFinish();
      props.onHide();
    });
  };

  const handleDuplicate = () => {
    generateBatchByLRN(props.LRN, batchName, false).then(() => {
      props.onFinish();
      props.onHide();
    });
  };

  return (
    <CustomModal show={true} onHide={props.onHide}>
      <ModalStyle.Header>
        <ModalStyle.Title>Regularization or Duplication</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={props.onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <ModalStyle.Content>
        <Input
          defaultBehaviour={true}
          placeholder="Batch name"
          value={batchName}
          onChange={(event) => setBatchName(event.target.value)}
        />
        <Style.ButtonsContainer>
          <Button type="button" primary onClick={handleDuplicate}>
            Duplicate
          </Button>
          <Button type="button" primary onClick={handleRegularization}>
            Regularization
          </Button>
        </Style.ButtonsContainer>
      </ModalStyle.Content>
    </CustomModal>
  );
};
