import { generateBatchesReport, generateDailyReport } from '../../../stores/declarationsSlice';
import { DailyReport } from './daily-report/daily-report.component';
import { FlexWrapContainer } from './b2c-reports.style';
import { BatchesArchivedReport } from './batches-archived-report/batches-archived-report';

export const B2CReports = () => {
  return (
    <>
      <FlexWrapContainer>
        <DailyReport title="Daily report" downloadReport={generateDailyReport} />
        <BatchesArchivedReport title="Batches Report" downloadReport={generateBatchesReport} />
      </FlexWrapContainer>
    </>
  );
};
